import { FormattedMessage, useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { WarningThreeIcon } from "ui/Icons/Icons";
import { BodyM } from "ui/Typography/Typography";

export function OrderCompletionWarningModal({ onClick }: { onClick: () => void }) {
  const intl = useIntl();

  return (
    <CtaModal
      isOpen
      onClose={() => {}}
      title={intl.formatMessage({ id: "order-completion-warning.title" })}
      body={
        <BodyM textAlign="center">
          <FormattedMessage id="order-completion-warning.body" />
        </BodyM>
      }
      ctaLabel={intl.formatMessage({
        id: "generic.actions.okay",
      })}
      onClickCta={onClick}
      hideCloseButton
      headerIcon={<WarningThreeIcon boxSize="80px" />}
    />
  );
}
