import { useRef } from "react";

import { ApolloError } from "@apollo/client";
import { FormattedMessage } from "react-intl";

import { PageName } from "analytics/events";
import { openOngoingActivityModal } from "core/stores/useLayoutStore";
import { OrderSummary } from "flows/Picking/components/OrderSummary";
import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { serializeOrderToStartPicking } from "flows/Picking/models/order/serializer";
import { OrderWithItems } from "flows/Picking/models/order/types";
import { useStartPickingMutation } from "flows/Picking/queries/order/order.generated";
import { ErrorTypeForAnalytics, useAnalytics } from "shared/hooks/useAnalytics";
import { useErrorToastWithAudio } from "shared/hooks/useErrorToastWithAudio";
import { useWaitingOrdersSound } from "shared/hooks/useWaitingOrdersSound";
import { useTimedActivityStore } from "shared/stores/useTimedActivityStore";
import { IntlMessageId } from "shared/types/lang";
import { Button } from "ui/Button/Button";
import { HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

const NOT_ELIGIBLE_FOR_PICKING_ERROR_MESSAGE = "Order state not eligible for picking";

function getErrorTextId(error: ApolloError): IntlMessageId {
  switch (error.graphQLErrors?.[0]?.message) {
    case NOT_ELIGIBLE_FOR_PICKING_ERROR_MESSAGE: {
      return "components.picking.new-order-summary.start-picking-error-title";
    }
    default:
      return "components.picking.new-order-summary.start-picking-generic-error-title";
  }
}

function getErrorTypeForAnalytics(error: ApolloError) {
  return error.graphQLErrors?.[0]?.message === NOT_ELIGIBLE_FOR_PICKING_ERROR_MESSAGE
    ? ErrorTypeForAnalytics.ORDER_ALREADY_TAKEN
    : ErrorTypeForAnalytics.UNKNOWN;
}

type NewOrderSummaryProps = { nextOrder: OrderWithItems | null };

export function NewOrderSummary({ nextOrder }: NewOrderSummaryProps) {
  const timedActivityInstance = useTimedActivityStore((state) => state.timedActivityInstance);

  const { send } = usePickingService();
  const { sendSegmentTrackEvent } = useAnalytics();

  useWaitingOrdersSound();
  const { showToastAndPlayError } = useErrorToastWithAudio({ errorToastId: "start_picking_error" });

  const [startPicking, { loading: loadingStartPicking }] = useStartPickingMutation();

  const requestInProgress = useRef(false);

  const onClickStartPicking = async () => {
    if (!nextOrder?.number || loadingStartPicking || requestInProgress.current) {
      return;
    }

    requestInProgress.current = true;

    if (isNotNullNorUndefined(timedActivityInstance)) {
      openOngoingActivityModal();
      requestInProgress.current = false;
      return;
    }

    try {
      const { data: startPickingData } = await startPicking({
        variables: { orderNumber: nextOrder.number, isAlwaysInbound: true },
      });

      if (startPickingData?.startPickingV2) {
        send({
          type: "START_PICKING",
          order: serializeOrderToStartPicking(startPickingData?.startPickingV2),
          origin: "PICKING_SCREEN",
        });
      }
    } catch (startPickingError: any) {
      showToastAndPlayError({
        title: getErrorTextId(startPickingError),
      });
      sendSegmentTrackEvent("errorShown", {
        screen_name: PageName.NEW_ORDER_FOR_PICKING_PAGE,
        component_value: getErrorTypeForAnalytics(startPickingError),
      });
    } finally {
      requestInProgress.current = false;
    }
  };

  return (
    <>
      <HeaderS color="pinkFlink.500">
        <FormattedMessage id="components.picking.new-order-summary.new-order-title" />
      </HeaderS>
      <OrderSummary order={nextOrder} state="START" />
      <Button
        mt="s200"
        isLoading={loadingStartPicking}
        onClick={onClickStartPicking}
        width="full"
        data-testid="button-start-picking"
        size="lg"
      >
        <FormattedMessage id="components.picking.new-order-summary.start-picking" />
      </Button>
    </>
  );
}
