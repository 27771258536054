import { useEffect, useMemo, useState } from "react";

import { Box, Flex, SlideFade, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, unstable_useBlocker as useBlocker } from "react-router-dom";

import { BbdClosestExpirationDate } from "flows/Inventory/shared/components/BbdClosestExpirationDate";
import { DatePickerModal } from "flows/Inventory/shared/components/DatePickerModal";
import { serializeInventoryProductFromSearchResult } from "flows/Inventory/shared/models/inventoryProduct/serializer";
import { InventoryProduct } from "flows/Inventory/shared/models/inventoryProduct/types";
import { BBD_CONFIG, getSelectableBbdDate } from "flows/Inventory/shared/utils/bbd";
import { Page } from "shared/components/Page";
import { YesNoModal } from "shared/components/YesNoModal";
import { Banner } from "ui/Banner/Banner";
import { Button } from "ui/Button/Button";
import { EditCalendarIcon } from "ui/Icons/Icons";
import { NavigationHeaderShelfNumber } from "ui/NavigationHeaderShelfNumber/NavigationHeaderShelfNumber";
import { PagePlaceholder } from "ui/PagePlaceholder/PagePlaceholder";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { BodyM } from "ui/Typography/Typography";
import { isNullOrUndefined } from "utils/tsHelpers";
import { transformChunks } from "utils/ui";

import { useBbdCorrectionService } from "../../hooks/useBbdCorrectionService";
import { useGetProductWithBbdQuery } from "../../queries/product/product.generated";

const DATE_NOT_ENTERED_BANNER_TIMEOUT_MS = 5000;
const DATE_NOT_ENTERED_BANNER_HEIGHT_NUMBER = 90;

type DeclareBBDCorrectionContentProps = {
  inventoryProduct: InventoryProduct;
};

function DeclareBBDCorrectionContent({
  inventoryProduct: {
    productName,
    productSku,
    productImageUrl,
    minDaysToBestBeforeDate,
    closestBbd,
  },
}: DeclareBBDCorrectionContentProps) {
  const intl = useIntl();

  const bbdCorrectionService = useBbdCorrectionService();

  const isUpdateProductBbdLoading = useSelector(bbdCorrectionService, (state) =>
    state.matches("declareBbdCorrections.loading"),
  );
  const isUpdateProductBbdDone = useSelector(bbdCorrectionService, (state) =>
    state.matches("declareBbdCorrections.done"),
  );

  const {
    onOpen: onOpenAdjustBddModal,
    isOpen: isAdjustBddModalOpen,
    onClose: onCloseAdjustBddModal,
  } = useDisclosure();

  const [showDateNotEnteredBanner, setShowDateNotEnteredBanner] = useState(
    isNullOrUndefined(closestBbd),
  );

  const [newClosestBbd, setClosestBbd] = useState<Date | undefined>(closestBbd ?? undefined);

  const blocker = useBlocker(!isUpdateProductBbdDone); // TODO: update logic when updateProductBBD implemented

  useEffect(() => {
    if (isUpdateProductBbdDone) {
      blocker.reset?.();
      bbdCorrectionService.send({ type: "TERMINATE" });
    }
  }, [isUpdateProductBbdDone, blocker, bbdCorrectionService]);

  useEffect(() => {
    if (showDateNotEnteredBanner) {
      setTimeout(() => {
        setShowDateNotEnteredBanner(false);
      }, DATE_NOT_ENTERED_BANNER_TIMEOUT_MS);
    }
  }, [showDateNotEnteredBanner]);

  const onConfirmNewDate = (newDate: Date) => {
    setClosestBbd(newDate);
    onCloseAdjustBddModal();
  };

  const handleUpdate = () => {
    if (isNullOrUndefined(newClosestBbd)) {
      return;
    }
    bbdCorrectionService.send({
      type: "UPDATE_PRODUCT_BBD",
      closestBbd: newClosestBbd,
      initialClosestBbd: closestBbd,
    });
  };

  const handleBlockedNavigationYesResponse = () => {
    blocker.proceed?.();
  };

  const handleBlockedNavigationNoResponse = () => {
    if (isNullOrUndefined(newClosestBbd)) {
      onOpenAdjustBddModal();
    }
    blocker.reset?.();
  };

  const minDate = getSelectableBbdDate(minDaysToBestBeforeDate);
  const maxDate = getSelectableBbdDate(BBD_CONFIG.MAX_DAYS);

  const isConfirmUpdateButtonDisabled =
    isUpdateProductBbdLoading || isNullOrUndefined(newClosestBbd);

  return (
    <>
      <Box position="absolute" top="0" left="0" zIndex="banner">
        <SlideFade
          unmountOnExit
          in={showDateNotEnteredBanner}
          offsetY={-DATE_NOT_ENTERED_BANNER_HEIGHT_NUMBER}
        >
          <Banner
            minH={`${DATE_NOT_ENTERED_BANNER_HEIGHT_NUMBER}px`}
            onClose={() => setShowDateNotEnteredBanner(false)}
            title={intl.formatMessage({
              id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.date-not-entered-yet-banner.title",
            })}
            descriptionSize="s"
            description={intl.formatMessage({
              id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.date-not-entered-yet-banner.description",
            })}
            data-testid="date-not-entered-yet-banner"
            data-isopen={showDateNotEnteredBanner}
          />
        </SlideFade>
      </Box>
      <Flex direction="column" flex={1}>
        <ProductInformation name={productName ?? ""} sku={productSku} imageUrl={productImageUrl} />
        <BbdClosestExpirationDate
          date={newClosestBbd}
          onClickAdjustBbdButton={onOpenAdjustBddModal}
          mt="s200"
          titleElement={
            <BodyM alignSelf="flex-start">
              <FormattedMessage
                id="flows.inventory.bbd-corrections.pages.declare-bbd-correction.closet.expiration-date"
                values={transformChunks({ b: { as: "strong" } })}
              />
            </BodyM>
          }
        />
      </Flex>
      <Box
        bg="white"
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor="grey.200"
        p="s200"
        width="100%"
      >
        <Button
          disabled={isConfirmUpdateButtonDisabled}
          isLoading={isUpdateProductBbdLoading}
          onClick={handleUpdate}
          width="full"
          flinkVariant="primary"
          size="lg"
          data-testid="confirm-update-button"
        >
          <FormattedMessage id="flows.inventory.bbd-corrections.pages.declare-bbd-correction.update" />
        </Button>
      </Box>
      {isAdjustBddModalOpen && ( // usually we keep the modal in the dom but here it seems that the modal content doesn't get unmounted when closing it
        <DatePickerModal
          isOpen={isAdjustBddModalOpen}
          onClickCancel={onCloseAdjustBddModal}
          onClickConfirm={onConfirmNewDate}
          initialValue={newClosestBbd}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
      <YesNoModal
        title={intl.formatMessage({
          id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.warn-quitting-page-modal.title",
        })}
        isOpen={blocker.state === "blocked"}
        yesLabel={intl.formatMessage({
          id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.warn-quitting-page-modal.yes",
        })}
        noLabel={intl.formatMessage({
          id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.warn-quitting-page-modal.no",
        })}
        onClickNo={handleBlockedNavigationNoResponse}
        onClickYes={handleBlockedNavigationYesResponse}
      />
    </>
  );
}

type DeclareBBDCorrectionErrorProps = {
  errorType: "notBbdProduct" | "hasOpenTask";
};

export function DeclareBBDCorrectionError({ errorType }: DeclareBBDCorrectionErrorProps) {
  const navigate = useNavigate();
  const intl = useIntl();

  const errorTextMessageId =
    errorType === "notBbdProduct"
      ? "flows.inventory.bbd-corrections.pages.declare-bbd-correction.error.not-bbd-product"
      : "flows.inventory.bbd-corrections.pages.declare-bbd-correction.error.has-open-task";

  return (
    <PagePlaceholder
      data-testid={`declare-bbd-correction-error-${
        errorType === "notBbdProduct" ? "not-bbd-product" : "has-open-task"
      }`}
      icon={<EditCalendarIcon />}
      title={intl.formatMessage({
        id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.error.this-date-cant-be-edited",
      })}
      subtitle={
        <FormattedMessage
          id={errorTextMessageId}
          values={transformChunks({ b: { as: "strong" }, br: { as: "br" } })}
        />
      }
      ctaLabel={intl.formatMessage({
        id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.error.got-it",
      })}
      onClickCta={() => navigate(-1)}
    />
  );
}

export function DeclareBBDCorrection() {
  const navigate = useNavigate();
  const intl = useIntl();

  const bbdCorrectionService = useBbdCorrectionService();

  const selectedProductSku = useSelector(
    bbdCorrectionService,
    (state) => state.context.selectedInventoryProductSku,
  );

  const isGetProductWithBBDTaskLoading = useSelector(bbdCorrectionService, (state) =>
    state.matches("declareBbdCorrections.fetchingProductWithBBDTask"),
  );

  const hasOpenTask = useSelector(bbdCorrectionService, (state) =>
    state.matches("declareBbdCorrections.hasOpenTask"),
  );

  const isNotABbdProduct = useSelector(bbdCorrectionService, (state) =>
    state.matches("declareBbdCorrections.notBbdProduct"),
  );

  const { data } = useGetProductWithBbdQuery({
    variables: { sku: selectedProductSku ?? "" },
    skip: isNullOrUndefined(selectedProductSku),
  });

  const serializedInventoryProduct = useMemo(() => {
    return data?.getProduct ? serializeInventoryProductFromSearchResult(data?.getProduct) : null;
  }, [data]);

  const displayedComponent = useMemo(() => {
    if (hasOpenTask) {
      return <DeclareBBDCorrectionError errorType="hasOpenTask" />;
    }
    if (isNotABbdProduct) {
      return <DeclareBBDCorrectionError errorType="notBbdProduct" />;
    }
    if (isGetProductWithBBDTaskLoading || isNullOrUndefined(serializedInventoryProduct)) {
      return null;
    }
    return <DeclareBBDCorrectionContent inventoryProduct={serializedInventoryProduct} />;
  }, [isGetProductWithBBDTaskLoading, hasOpenTask, isNotABbdProduct, serializedInventoryProduct]);

  return (
    <Page isFull isLoading={isGetProductWithBBDTaskLoading} isBgGrey h="100%">
      <NavigationHeaderShelfNumber
        title={intl.formatMessage({
          id: "flows.inventory.bbd-corrections.pages.declare-bbd-correction.header-title",
        })}
        shelfLetter={serializedInventoryProduct?.shelfLetter ?? null}
        shelfNumber={serializedInventoryProduct?.shelfNumber ?? null}
        onClickGoBack={() => {
          navigate(-1);
        }}
      />
      {displayedComponent}
    </Page>
  );
}
