import produce from "immer";
import { createMachine, assign } from "xstate";

import { routes } from "config/routes";
import { Order } from "flows/Picking/models/order/types";
import { XstateRouteSyncMeta } from "shared/types/xstate";
import { sortByShelf } from "utils/item";
import { wrongEventErrorHandlerFactory } from "utils/xstate";

import { PickingMachineContext, PickingEvents, ItemPickingState } from "./types";
import {
  getActiveItemFromContext,
  getItemPickingStateRef,
  getItemWithPickingState,
  getMissingItemsFromContext,
  getPickingStateMap,
  selectNextItemInContext,
} from "./utils";

const initialItemPickingState: ItemPickingState = {
  state: "IDLE",
  pickedQuantity: 0,
  hasBarcodeIssue: false,
};

export const contextInitialState: PickingMachineContext = {
  elapsedTime: 0,
  order: null,
  origin: null,
  pickedGiftsQuantity: 0,
  totalGiftsQuantity: 0,
  pickedCampaignsQuantity: 0,
  totalCampaignsQuantity: 0,
  pickedRemoveFlyersQuantity: 0,
  totalRemoveFlyersQuantity: 0,
  itemsIdsSortedByShelf: [],
  itemsPickingState: {},
  skippedItemsIdsSortedByShelf: [],
  skippedItemsPickingState: {},
  itemsMap: {},
  inputMethod: null,
  containersIds: [],
  shelvesIds: [],
};

export const PICKING_MACHINE_NAME = "pickingMachine";

export const pickingMachine = createMachine(
  {
    preserveActionOrder: true,
    id: PICKING_MACHINE_NAME,
    type: "parallel",
    predictableActionArguments: true,
    tsTypes: {} as import("./machine.typegen").Typegen0,
    schema: {
      context: {} as PickingMachineContext,
      events: {} as PickingEvents,
    },
    on: {
      "*": { actions: "wrongEventErrorHandler" },
      RESET: {
        target: [".picking.idle", ".endPicking.idle"],
        actions: "resetMachineState",
      },
    },
    context: contextInitialState,
    states: {
      picking: {
        initial: "idle",
        states: {
          idle: {
            on: {
              START_PICKING: {
                target: "scanningItems",
                actions: ["setOrder", "setOrigin", "trackOrderPicked"],
              },
              GO_TO_START_MANUAL_PICKING: {
                target: "startManualPicking",
              },
            },
            meta: {
              routeSync: {
                path: routes.picking.root,
                replace: true,
              } as XstateRouteSyncMeta,
            },
          },
          startManualPicking: {
            meta: {
              routeSync: {
                path: routes.picking.manual,
              } as XstateRouteSyncMeta,
            },
            on: {
              START_PICKING: {
                target: "scanningItems",
                actions: ["setOrder", "setOrigin", "trackOrderPicked"],
              },
            },
          },
          pickingStuck: {
            meta: {
              routeSync: {
                path: routes.picking.incomplete,
              } as XstateRouteSyncMeta,
            },
            on: {
              BACK_TO_PICKING: {
                target: "scanningItems",
              },
            },
          },
          scanningItems: {
            invoke: {
              id: "incInterval",

              src: (context) => (callback) => {
                // This will send the 'INC' event to the parent every second
                if (context.elapsedTime > 0) {
                  return () => {};
                }

                const id = setInterval(() => callback("INCREMENT_ELAPSED_TIME"), 1000);

                // Perform cleanup
                return () => clearInterval(id);
              },
            },
            meta: {
              routeSync: {
                path: routes.picking.start,
              } as XstateRouteSyncMeta,
            },
            on: {
              INCREMENT_ITEM_QUANTITY: {
                actions: ["incrementItemQuantity", "sendSegmentOrderProgressed"],
              },
              DECREMENT_ITEM_QUANTITY: {
                actions: ["decrementItemQuantity"],
              },
              INCREMENT_GIFT_QUANTITY: {
                actions: "incrementGiftQuantity",
                cond: (context) =>
                  context.order?.reseller === "FLINK" &&
                  context.order?.isNewCustomer === true &&
                  context.pickedGiftsQuantity < context.totalGiftsQuantity,
              },
              INCREMENT_CAMPAIGN_QUANTITY: {
                actions: "incrementCampaignQuantity",
                cond: (context) =>
                  context.order?.reseller === "FLINK" &&
                  context.order?.isNewCustomer === true &&
                  context.pickedCampaignsQuantity < context.totalCampaignsQuantity,
              },
              INCREMENT_REMOVE_FLYERS_QUANTITY: {
                actions: "incrementRemoveFlyersQuantity",
                cond: (context) =>
                  ["UBER-EATS", "UBER-EATS-CARREFOUR", "WOLT", "JUST-EAT"].includes(
                    context.order?.reseller ?? "",
                  ) && context.pickedRemoveFlyersQuantity < context.totalRemoveFlyersQuantity,
              },
              SKIP_ITEM: {
                actions: ["skipItem", "sendSegmentOrderProgressed"],
              },
              RESET_ITEM: {
                actions: ["sendSegmentOrderProgressed", "resetItem"],
              },
              INCREMENT_ELAPSED_TIME: {
                actions: "setElapsedTime",
              },
              GO_TO_SCAN_CONTAINERS: {
                target: "scanningContainers",
                actions: "trackOrderPickingFinished",
              },
              END_PICKING: {
                actions: "trackOrderPickingFinished",
              },
              GO_TO_STUCK: {
                target: "pickingStuck",
              },
            },
          },
          scanningContainers: {
            meta: {
              routeSync: {
                path: routes.picking.containers,
              } as XstateRouteSyncMeta,
            },
            on: {
              ADD_CONTAINER: {
                actions: "addContainer",
              },
              REMOVE_CONTAINER: {
                actions: "removeContainer",
              },
              RESET_CONTAINERS: {
                actions: "resetContainers",
              },
              GO_BACK: {
                target: "scanningItems",
              },
              GO_TO_ASSIGN_SHELVES: {
                target: "assigningShelves",
                actions: ["sendSegmentContainerStatus"],
              },
              END_PICKING: {
                actions: "sendSegmentContainerStatus",
              },
              SET_INPUT_METHOD: {
                actions: "setInputMethod",
              },
            },
          },
          assigningShelves: {
            meta: {
              routeSync: {
                path: routes.picking.shelves,
              } as XstateRouteSyncMeta,
            },
            on: {
              ADD_SHELF: {
                actions: "addShelf",
              },
              REMOVE_SHELF: {
                actions: "removeShelf",
              },
              RESET_SHELVES: {
                actions: "resetShelves",
              },
              GO_BACK: [
                {
                  target: "scanningContainers",
                },
              ],

              END_PICKING: {
                actions: "sendSegmentShelfStatus",
              },
              SET_INPUT_METHOD: {
                actions: "setInputMethod",
              },
            },
          },
          finished: {
            meta: {
              routeSync: {
                path: routes.picking.end,
              } as XstateRouteSyncMeta,
            },
            on: {
              FINISH_PICKING: {
                actions: "resetMachineState",
                target: ["idle"],
              },
            },
          },
        },
      },
      endPicking: {
        initial: "idle",
        states: {
          idle: {
            on: {
              END_PICKING: {
                target: "loading",
              },
              CONFIRM_ORDER_COMPLETION: {
                target: "#pickingMachine.picking.finished",
                actions: ["hideOrderCompletionWarning", "trackOrderPacked"],
              },
            },
          },
          loading: {
            invoke: {
              id: "endPicking",
              src: "endPicking",
              data: (ctx) => ({
                orderNumber: ctx.order?.number,
                missingItems: getMissingItemsFromContext(ctx),
                containersIds: ctx.containersIds,
                shelvesIds: ctx.shelvesIds,
              }),
              onDone: {
                target: ["idle", "#pickingMachine.picking.finished"],
                actions: ["trackOrderPacked"],
              },
              onError: [
                {
                  cond: (_, event) =>
                    (event.data?.toString() ?? "").includes(
                      "Error: orderStateNotEligibleForPacking",
                    ),
                  target: "idle",
                  actions: "showOrderCompletionWarning",
                },
                { target: "error" },
              ],
            },
          },
          error: {
            on: {
              RETRY_END_PICKING: {
                target: "loading",
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      // Unused "context" value to avoid triggering ts error
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resetMachineState: assign((context) => ({ ...contextInitialState })),
      setInputMethod: assign((_, event) => ({ inputMethod: event.inputMethod })),
      // PICKING FLOW ACTIONS
      incrementItemQuantity: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          // get the current selectedItem
          const selectedItem = getItemWithPickingState(event.itemId, ctxDraft, event.isSkippedItem);
          if (!selectedItem) {
            return;
          }

          const newItemPickedQuantity = selectedItem.pickedQuantity + 1;

          // Get the reference state map
          const pickingStateMap = getPickingStateMap(ctxDraft, event.isSkippedItem);

          // increment its quantity
          if (newItemPickedQuantity <= selectedItem.item.quantity) {
            pickingStateMap[event.itemId].pickedQuantity = newItemPickedQuantity;
          }
          if (event.reportedBarcodeIssue) {
            pickingStateMap[event.itemId].hasBarcodeIssue = event.reportedBarcodeIssue;
          }
          if (newItemPickedQuantity === selectedItem.item.quantity) {
            pickingStateMap[event.itemId].state = "DONE";
            selectNextItemInContext(ctxDraft);
          }
        });
        return nextState;
      }),
      decrementItemQuantity: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          // check if selectedItem is skipped
          const isSkippedItem = ctx.skippedItemsIdsSortedByShelf.includes(event.itemId);

          // get selectedItem
          const selectedItem = getItemWithPickingState(event.itemId, ctxDraft, isSkippedItem);
          if (!selectedItem) {
            return;
          }

          // get selectedItem's pickedQuantity
          const currentItemPickedQuantity = selectedItem.pickedQuantity;
          if (!currentItemPickedQuantity) {
            return;
          }

          // update state for currentActiveItem
          const currentActiveItem =
            getActiveItemFromContext(ctxDraft, false) || getActiveItemFromContext(ctxDraft, true);

          if (currentActiveItem && currentActiveItem.item.id !== event.itemId) {
            const isSkippedActiveItem = currentActiveItem.isSkipped;
            const selectedPickingStateMap = getPickingStateMap(ctxDraft, isSkippedActiveItem);
            selectedPickingStateMap[currentActiveItem.item.id].state = "IDLE";
          }

          // get the reference state map for selectedItem
          const pickingStateMap = getPickingStateMap(ctxDraft, isSkippedItem);
          // update state for selectedItem
          pickingStateMap[event.itemId].state = "PICKING";

          // decrease its quantity
          const newItemPickedQuantity = selectedItem.pickedQuantity - 1;
          pickingStateMap[event.itemId].pickedQuantity = newItemPickedQuantity;
        });
        return nextState;
      }),
      skipItem: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          // get the current selectedItem state ref
          const selectedItemPickingStateRef = getItemPickingStateRef(
            event.itemId,
            ctxDraft,
            event.isSkippedItem,
          );

          if (!selectedItemPickingStateRef) {
            return;
          }

          // Set item's state to DONE
          selectedItemPickingStateRef.state = "DONE";

          if (
            !event.isSkippedItem &&
            !ctxDraft.skippedItemsIdsSortedByShelf.includes(event.itemId)
          ) {
            // push the item id to skipped items ids list
            ctxDraft.skippedItemsIdsSortedByShelf.push(event.itemId);
            // sort the skippedItemsList
            ctxDraft.skippedItemsIdsSortedByShelf.sort(
              (a, b) =>
                ctxDraft.itemsIdsSortedByShelf.indexOf(a) -
                ctxDraft.itemsIdsSortedByShelf.indexOf(b),
            );
            // add the item to skipped items state map
            ctxDraft.skippedItemsPickingState[event.itemId] = {
              ...initialItemPickingState,
              pickedQuantity: selectedItemPickingStateRef.pickedQuantity,
            };

            // remove skippedItem from piking list
            ctxDraft.itemsIdsSortedByShelf = ctxDraft.itemsIdsSortedByShelf.filter(
              (id) => id !== event.itemId,
            );
          }

          selectNextItemInContext(ctxDraft);
        });
        return nextState;
      }),
      resetItem: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          // If item has been skipped
          if (ctxDraft.skippedItemsIdsSortedByShelf.indexOf(event.itemId) > -1) {
            // Ensure the itemId is removed from skipped items ids list
            ctxDraft.skippedItemsIdsSortedByShelf = ctxDraft.skippedItemsIdsSortedByShelf.filter(
              (skippedItemId) => skippedItemId !== event.itemId,
            );
            // Ensure that the item is not present in skipped items picking state map
            delete ctxDraft.skippedItemsPickingState[event.itemId];
          }

          // reset item's state
          ctxDraft.itemsPickingState[event.itemId] = { ...initialItemPickingState };

          const currentlyActiveItem =
            getActiveItemFromContext(ctxDraft, false) || getActiveItemFromContext(ctxDraft, true);

          if (currentlyActiveItem) {
            const pickingStateMap = getPickingStateMap(ctxDraft, currentlyActiveItem.isSkipped);
            // set the currently picking item state ot IDLE
            pickingStateMap[currentlyActiveItem.item.id].state = "IDLE";
          }
          selectNextItemInContext(ctxDraft);
        });
        return nextState;
      }),
      // CONTAINER AND SHELF ACTIONS
      addContainer: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.containersIds = ctx.containersIds.includes(event.containerId)
            ? ctx.containersIds
            : [...ctx.containersIds, event.containerId];
        });
        return nextState;
      }),
      removeContainer: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.containersIds = ctx.containersIds.filter(
            (containerId) => containerId !== event.containerId,
          );
        });
        return nextState;
      }),
      resetContainers: assign((ctx) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.containersIds = [];
        });
        return nextState;
      }),
      addShelf: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.shelvesIds = [...ctx.shelvesIds, event.shelfId];
        });
        return nextState;
      }),
      removeShelf: assign((ctx, event) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.shelvesIds = ctx.shelvesIds.filter((shelfId) => shelfId !== event.shelfId);
        });
        return nextState;
      }),
      resetShelves: assign((ctx) => {
        const nextState = produce(ctx, (ctxDraft) => {
          ctxDraft.shelvesIds = [];
        });
        return nextState;
      }),
      setElapsedTime: assign((ctx) => ({
        elapsedTime: ctx.elapsedTime + 1,
      })),
      incrementGiftQuantity: assign((ctx) => ({
        pickedGiftsQuantity: ctx.pickedGiftsQuantity + 1,
      })),
      incrementCampaignQuantity: assign((ctx) => ({
        pickedCampaignsQuantity: ctx.pickedCampaignsQuantity + 1,
      })),
      incrementRemoveFlyersQuantity: assign((ctx) => ({
        pickedRemoveFlyersQuantity: ctx.pickedRemoveFlyersQuantity + 1,
      })),
      // @ts-ignore
      setOrder: assign((ctx, event: { order: Order }) => {
        if (!event.order || !event.order.items) {
          return ctx;
        }
        const itemsIdsSortedByShelf = event.order.items
          .slice()
          .sort(sortByShelf)
          .map((item) => item.id);
        const { itemsPickingState, itemsMap } = event.order.items.reduce<
          Pick<PickingMachineContext, "itemsPickingState" | "itemsMap">
        >(
          (acc, curr) => {
            const isFirstItemInList = itemsIdsSortedByShelf[0] === curr.id;
            return {
              itemsMap: {
                ...acc.itemsMap,
                [curr.id]: curr,
              },
              itemsPickingState: {
                ...acc.itemsPickingState,
                [curr.id]: {
                  ...initialItemPickingState,
                  state: isFirstItemInList ? "PICKING" : "IDLE",
                },
              },
            };
          },
          { itemsPickingState: {}, itemsMap: {} },
        );
        // number of gifts should be given to the customer (for now only one is possible)
        const isEligibleForGift = event.order.reseller === "FLINK" && event.order.isNewCustomer;
        const totalArtificialItem = isEligibleForGift ? 1 : 0;

        const totalRemoveFlyersQuantity = [
          "UBER-EATS",
          "UBER-EATS-CARREFOUR",
          "WOLT",
          "JUST-EAT",
        ].includes(event.order.reseller ?? "")
          ? 1
          : 0;

        return {
          order: event.order,
          itemsIdsSortedByShelf,
          itemsPickingState,
          itemsMap,
          totalGiftsQuantity: totalArtificialItem,
          totalCampaignsQuantity: totalArtificialItem,
          totalRemoveFlyersQuantity,
        };
      }),
      setOrigin: assign((_, event) => ({ origin: event.origin })),
      wrongEventErrorHandler: wrongEventErrorHandlerFactory(PICKING_MACHINE_NAME),
    },
  },
);
