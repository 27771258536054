import { useCallback, useEffect, useState } from "react";

import {
  chakra,
  FormControl,
  FormErrorMessage,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import shallow from "zustand/shallow";

import { TGTGBagAdditionMethod } from "analytics/events";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { IntlMessageId } from "shared/types/lang";
import { Button } from "ui/Button/Button";
import { BodyM, HeaderS } from "ui/Typography/Typography";

import { GENERATED_SKU_PREFIX, useTGTGBagStore } from "../stores/useTGTGBagStore";

interface ProductNotListedModalProps {
  isOpen: boolean;
}

function ProductNotListedModal({ isOpen }: ProductNotListedModalProps) {
  const intl = useIntl();
  const { sendSegmentTrackEvent } = useAnalytics();

  const { productToAdd, addProduct, setProductToAdd, setShowProductNotListedModal } =
    useTGTGBagStore(
      (state) => ({
        productToAdd: state.productToAdd,
        addProduct: state.addProduct,
        setProductToAdd: state.setProductToAdd,
        setShowProductNotListedModal: state.setShowProductNotListedModal,
      }),
      shallow,
    );
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [formError, setFormError] = useState({ name: false, price: false });

  useEffect(() => {
    if (productToAdd?.productName) {
      setProductName(productToAdd.productName);
    }
  }, [productToAdd?.productName]);

  const handleCloseModal = useCallback(() => {
    setProductToAdd(null);
    setProductName("");
    setProductPrice("");
    setShowProductNotListedModal(false);
  }, [setProductToAdd, setShowProductNotListedModal]);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const isNameValid = productName.trim() !== "";
    const price = parseFloat(productPrice);
    const isPriceValid = !Number.isNaN(price) && price > 0;

    if (!isNameValid || !isPriceValid) {
      setFormError({ name: !isNameValid, price: !isPriceValid });
      return;
    }
    const id = productToAdd?.id || uuidv4();
    const productSku = productToAdd?.productSku || `${GENERATED_SKU_PREFIX}${id.slice(0, 8)}`;
    addProduct(
      {
        id,
        ean: productToAdd?.ean ?? "",
        productSku,
        productName,
        productImageUrl: productToAdd?.productImageUrl ?? null,
        productPrice: price,
        countryOfOriginCode: productToAdd?.countryOfOriginCode ?? null,
        countryOfOriginName: productToAdd?.countryOfOriginName ?? null,
      },
      TGTGBagAdditionMethod.Manual,
      sendSegmentTrackEvent,
    );
    handleCloseModal();
  };

  const translatedLabels: { title: IntlMessageId; body: IntlMessageId } =
    productToAdd?.productName && !productToAdd?.productPrice
      ? {
          title: "components.product-not-listed-modal.no-price.title",
          body: "components.product-not-listed-modal.no-price.body",
        }
      : {
          title: "components.product-not-listed-modal.title",
          body: "components.product-not-listed-modal.body",
        };

  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={() => {}}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        <chakra.form w="100%" onSubmit={handleFormSubmit}>
          <ModalHeader textAlign="center" pb="s100">
            <HeaderS>
              <FormattedMessage id={translatedLabels.title} />
            </HeaderS>
          </ModalHeader>
          <ModalBody textAlign="center">
            <BodyM color="grey.700" sx={{ whiteSpace: "pre-wrap" }}>
              <FormattedMessage id={translatedLabels.body} values={{ br: <br /> }} />
            </BodyM>
            <FormControl mt="s300" isInvalid={formError.name}>
              <Input
                h="48px"
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                placeholder={intl.formatMessage({
                  id: "components.product-not-listed-modal.placeholder.product-name",
                })}
                autoComplete="off"
                isReadOnly={!!productToAdd?.productName}
              />
              {formError.name && (
                <FormErrorMessage>
                  <FormattedMessage id="components.product-not-listed-modal.error" />
                </FormErrorMessage>
              )}
            </FormControl>
            <FormControl mt="s100" isInvalid={formError.price}>
              <Input
                h="48px"
                type="number"
                step="any" // Allows decimal values
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                placeholder={intl.formatMessage({
                  id: "components.product-not-listed-modal.placeholder.product-price",
                })}
                autoComplete="off"
              />
              {formError.price && (
                <FormErrorMessage>
                  <FormattedMessage id="components.product-not-listed-modal.error" />
                </FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter flexDir="column">
            <Button
              h="48px"
              type="submit"
              width="full"
              flinkVariant="primary"
              size="lg"
              data-testid="yes-no-modal-yes-button"
            >
              <FormattedMessage id="components.product-not-listed-modal.save-item" />
            </Button>
            <Button
              h="48px"
              mt="s100"
              onClick={handleCloseModal}
              width="full"
              flinkVariant="secondary"
              size="lg"
              data-testid="yes-no-modal-no-button"
            >
              <FormattedMessage id="generic.actions.back" />
            </Button>
          </ModalFooter>
        </chakra.form>
      </ModalContent>
    </Modal>
  );
}

export default ProductNotListedModal;
