import { useCallback, useState } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { TGTGBagAdditionMethod } from "analytics/events";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { countryNameFromCode } from "libs/countryNames";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useBarcodeScanner } from "shared/hooks/useBarcodeScanner";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { serializeSearchUnitWithPriceResult } from "shared/models/products/serializer";
import { useSearchUnitsByEanWithPriceLazyQuery } from "shared/queries/products/products.generated";

import { useTGTGBagStore } from "../stores/useTGTGBagStore";

const GET_UNITS_BY_EAN_ERROR_TOAST_ID = "get_units_by_ean_error_toast_id";

export function useFetchProductsByEanWithPrice() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { addProduct, setProductToAdd, setShowProductNotListedModal } = useTGTGBagStore(
    (state) => ({
      addProduct: state.addProduct,
      setProductToAdd: state.setProductToAdd,
      setShowProductNotListedModal: state.setShowProductNotListedModal,
    }),
  );
  const [isScannedProductLoading, setIsScannedProductLoading] = useState(false);
  const { showToastUI } = useCustomToast();
  const { processBarcode } = useBarcodeScanner();
  const { sendSegmentTrackEvent } = useAnalytics();

  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";
      datadogRum.addError("Error while fetching units by ean", { errorMessage });
      showToastUI({
        id: GET_UNITS_BY_EAN_ERROR_TOAST_ID,
        title: "get_units_by_ean_error_toast_id",
        description: "get_units_by_ean_error_toast_description",
      });
    },
    [showToastUI],
  );

  const [searchUnitsByEan] = useSearchUnitsByEanWithPriceLazyQuery({
    fetchPolicy: "no-cache",
    onError: handleError,
  });

  const onScan = useCallback(
    async (scannedValue: string) => {
      const ean = processBarcode(scannedValue);
      if (!ean) return;

      setIsScannedProductLoading(true);
      try {
        const { data } = await searchUnitsByEan({
          variables: {
            searchUnitsByEanInput: { ean },
            isAlwaysInbound: true,
          },
        });
        const units = data?.searchUnitsByEan?.units.filter((unit) => unit.type === "single") ?? [];
        if (!units.length) {
          setProductToAdd({
            id: "",
            ean,
            productSku: "",
            productName: null,
            productImageUrl: null,
            productPrice: null,
            countryOfOriginCode: null,
            countryOfOriginName: null,
          });
          setShowProductNotListedModal(true);
          return;
        }

        const uniqueProducts = Array.from(
          new Map(
            units.map((unit) => {
              const serializedProduct = serializeSearchUnitWithPriceResult(unit);
              return [
                serializedProduct.productSku,
                {
                  ...serializedProduct,
                  countryOfOriginName:
                    countryNameFromCode(appLanguage, serializedProduct.countryOfOriginCode) ?? "",
                },
              ];
            }),
          ).values(),
        );
        const productWithoutPrice = uniqueProducts.find((product) => product.productPrice === null);
        if (productWithoutPrice) {
          setProductToAdd(productWithoutPrice);
          setShowProductNotListedModal(true);
          return;
        }
        uniqueProducts.forEach((product) =>
          addProduct(product, TGTGBagAdditionMethod.Scanned, sendSegmentTrackEvent),
        );
      } catch (error) {
        handleError(error);
      } finally {
        setIsScannedProductLoading(false);
      }
    },
    [
      addProduct,
      appLanguage,
      handleError,
      processBarcode,
      searchUnitsByEan,
      sendSegmentTrackEvent,
      setProductToAdd,
      setShowProductNotListedModal,
    ],
  );

  return { onScan, isScannedProductLoading };
}
