import { useCallback, useEffect, useState } from "react";

import { Box, Divider, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import {
  ConfirmLargeQuantityChangeModal,
  shouldShowLargeQuantityChangeModal,
} from "flows/Inbound/components/ConfirmLargeQuantityChangeModal";
import { ProductNotAssignedForTheHubErrorModal } from "flows/Inbound/components/ProductNotAssignedForTheHubErrorModal";
import { useGetProductStockQuery } from "flows/Inventory/shared/queries/inventoryEntry/inventoryEntry.generated";
import { Page } from "shared/components/Page";
import { useCountryNames } from "shared/hooks/useCountryNames";
import { Button } from "ui/Button/Button";
import { NavigationHeaderShelfNumber } from "ui/NavigationHeaderShelfNumber/NavigationHeaderShelfNumber";
import { ProductFacing } from "ui/ProductFacing/ProductFacing";
import { ProductInformation } from "ui/ProductInformation/ProductInformation";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useRestockingServiceStore } from "../../RestockingList/stores/restockingServiceStore";
import { StockCorrector } from "../components/StockCorrector";
import { useSelectedInventoryProductFromContext } from "../hooks/useStockCorrectionMachineSelectors";
import { useStockCorrectionService } from "../hooks/useStockCorrectionService";

export function DeclareStockCorrectionPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const stockCorrectionService = useStockCorrectionService();
  const inventoryUnit = useSelectedInventoryProductFromContext();
  const [expired, setExpired] = useState(0);
  const [damaged, setDamaged] = useState(0);
  const [correction, setCorrection] = useState(0);
  const [tooGoodToGo, setTooGoodToGo] = useState(0);
  const { isNavigatingToStockCorrection, productName } = useRestockingServiceStore();
  const {
    isOpen: isProductNotAssignedForTheHubErrorModalOpen,
    onOpen: openProductNotAssignedForTheHubErrorModal,
    onClose: closeProductNotAssignedForTheHubErrorModal,
  } = useDisclosure();

  const { data: productStockData, loading: isGetProductStockLoading } = useGetProductStockQuery({
    fetchPolicy: "network-only",
    variables: { sku: inventoryUnit?.productSku! },
    skip: !inventoryUnit?.productSku,
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.extensions?.code === "PRODUCT_NOT_FOUND") {
        openProductNotAssignedForTheHubErrorModal();
      }
    },
  });

  const initialStock = productStockData?.getProduct.inventoryEntry.stock.shelf ?? 0;
  const countryOfOriginName = useCountryNames(inventoryUnit?.countryOfOriginCode ?? null);

  const [newStock, setNewStock] = useState(initialStock);
  const [hasConfirmedLargeQuantityChange, setHasConfirmedLargeQuantityChange] = useState(false);
  const isStockUpdated = initialStock !== newStock;
  const isIncreaseBtnDisabled = newStock === 0;

  const {
    isOpen: isConfirmLargeQuantityChangeModalOpen,
    onOpen: openConfirmLargeQuantityChangeModal,
    onClose: closeConfirmLargeQuantityChangeModal,
  } = useDisclosure();

  const isDeclareStockCorrectionsLoading = useSelector(stockCorrectionService, (state) =>
    state.matches("declareStockCorrections.loading"),
  );

  useEffect(() => {
    setHasConfirmedLargeQuantityChange(false);
  }, [newStock]);

  useEffect(() => {
    if (isNavigatingToStockCorrection && productName) {
      stockCorrectionService.send({
        type: "SCAN_PRODUCT_FROM_TEXT",
        query: productName,
      });
    }
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(() => {
    if (!inventoryUnit) return;

    if (
      shouldShowLargeQuantityChangeModal(initialStock, newStock) &&
      !hasConfirmedLargeQuantityChange
    ) {
      openConfirmLargeQuantityChangeModal();
      return;
    }

    stockCorrectionService.send({
      type: "UPDATE_PRODUCT_STOCK",
      initial: initialStock,
      expired,
      damaged,
      correction,
      tooGoodToGo,
    });

    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expired,
    damaged,
    correction,
    tooGoodToGo,
    inventoryUnit,
    openConfirmLargeQuantityChangeModal,
    newStock,
    initialStock,
    hasConfirmedLargeQuantityChange,
  ]);

  useEffect(() => {
    if (hasConfirmedLargeQuantityChange) {
      onSubmit();
    }
  }, [hasConfirmedLargeQuantityChange, onSubmit]);

  useEffect(() => {
    setNewStock(initialStock - expired - damaged - tooGoodToGo + correction);
  }, [expired, damaged, tooGoodToGo, correction, initialStock]);

  return (
    <Page isFull isLoading={isGetProductStockLoading} isBgGrey h="100%">
      <NavigationHeaderShelfNumber
        title={intl.formatMessage({
          id: "components.inventory.declare-stock-corrections.header-title",
        })}
        shelfLetter={inventoryUnit?.shelfLetter ?? null}
        shelfNumber={inventoryUnit?.shelfNumber ?? null}
        onClickGoBack={() => {
          navigate(-1);
        }}
      />
      {isNotNullNorUndefined(inventoryUnit) && isNotNullNorUndefined(inventoryUnit.productName) && (
        <Box p="s200" bg="white" w="100%">
          <ProductInformation
            p={0}
            variantSize="s"
            imageUrl={inventoryUnit.productImageUrl}
            name={inventoryUnit.productName}
            stock={newStock}
            countryOfOrigin={countryOfOriginName}
            stockStatus={isStockUpdated ? "updated" : "unchanged"}
            sku={inventoryUnit.productSku}
          />
          <Divider py="s100" />
          <ProductFacing
            isShelvedInHandlingUnits={!!productStockData?.getProduct.isShelvedInHandlingUnits}
            numberOfFacings={productStockData?.getProduct.numberOfShelfFacings ?? 0}
            bg="white"
          />
        </Box>
      )}
      <Box flex={1} flexDirection="column" w="100%" overflowY="scroll" mt="s200">
        <ConfirmLargeQuantityChangeModal
          onClickConfirm={() => setHasConfirmedLargeQuantityChange(true)}
          onClickDecline={closeConfirmLargeQuantityChangeModal}
          isOpen={isConfirmLargeQuantityChangeModalOpen}
          quantity={newStock}
        />
        <ProductNotAssignedForTheHubErrorModal
          onClickYes={() => {
            closeProductNotAssignedForTheHubErrorModal();
            navigate(routes.inbound.root);
          }}
          onClickNo={() => {
            closeProductNotAssignedForTheHubErrorModal();
            navigate(-1);
          }}
          isOpen={isProductNotAssignedForTheHubErrorModalOpen}
        />
        <Box p="s200" pt={0}>
          <StockCorrector
            expired={expired}
            damaged={damaged}
            correction={correction}
            isIncreaseBtnDisabled={isIncreaseBtnDisabled}
            setCorrection={setCorrection}
            setDamaged={setDamaged}
            setExpired={setExpired}
            toGoodToGo={tooGoodToGo}
            setToGoodToGo={setTooGoodToGo}
          />
        </Box>
      </Box>
      <Box bg="white" p="s200" w="100%" borderTopWidth="1px" borderColor="grey.200">
        <Button
          onClick={onSubmit}
          isLoading={isDeclareStockCorrectionsLoading}
          data-testid="stock-correction-submit-btn"
          size="lg"
          width="100%"
        >
          {intl.formatMessage({
            id: "components.inventory.declare-stock-corrections.confirm-stock",
          })}
        </Button>
      </Box>
    </Page>
  );
}
