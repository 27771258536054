import create from "zustand";

import { BagProgressed, TGTGBagAdditionMethod, TGTGBagProgressAction } from "analytics/events";
import { TranslatedProductWithPrice } from "shared/models/products/types";

export const GENERATED_SKU_PREFIX = "GEN-";

export type ProductsMap = Record<string, ProductWithQuantity>;
export type ProductWithQuantity = TranslatedProductWithPrice & {
  quantity: number;
};

type TGTGBagStore = {
  bagId: string | null;
  bagKey: string | null;
  bagSku: string | null;
  price: number | null;
  totalPrice: number;
  productsMap: ProductsMap;
  products: string[];
  productToAdd: TranslatedProductWithPrice | null;
  showProductNotListedModal: boolean;
  isInhouse: boolean;
};

type TrackBagProgress = (name: BagProgressed["name"], payload: BagProgressed["payload"]) => void;

type TGTGBagStoreWithMethods = TGTGBagStore & {
  setSelectedBag(
    isInhouse: boolean,
    bagId: string,
    bagKey: string | null,
    price: number | null,
    bagSku: string | null,
  ): void;
  addProduct(
    newProduct: TranslatedProductWithPrice,
    method: TGTGBagAdditionMethod,
    trackBagProgress: TrackBagProgress,
  ): void;
  setProductToAdd(product: TranslatedProductWithPrice | null): void;
  setShowProductNotListedModal: (isOpen: boolean) => void;
  increaseQuantity(sku: string, trackBagProgress: TrackBagProgress): void;
  decreaseQuantity(sku: string, trackBagProgress: TrackBagProgress): void;
  reset: () => void;
};

const initialState: TGTGBagStore = {
  bagId: null,
  bagKey: null,
  bagSku: null,
  price: null,
  totalPrice: 0,
  productsMap: {},
  products: [],
  productToAdd: null,
  showProductNotListedModal: false,
  isInhouse: false,
};

// Helper function
const calculateTotalPrice = (currentTotal: number, change: number) =>
  Math.round((currentTotal + change) * 100) / 100;
const getValidSku = (sku: string) => (sku.startsWith(GENERATED_SKU_PREFIX) ? null : sku);

export const useTGTGBagStore = create<TGTGBagStoreWithMethods>((set) => ({
  ...initialState,
  setSelectedBag: (isInhouse, bagId, bagKey, price, bagSku) =>
    set({ isInhouse, bagId, bagKey, price, bagSku }),
  addProduct: (newProduct, method, trackBagProgress) =>
    set((state) => {
      const { productPrice, productName } = newProduct;
      if (!productPrice || !productName) return state;
      const sku = newProduct.productSku;
      const existingProduct = state.productsMap[sku];
      const newQuantity = existingProduct ? existingProduct.quantity + 1 : 1;
      const updatedProductsMap = {
        ...state.productsMap,
        [sku]: {
          ...newProduct,
          quantity: newQuantity,
        },
      };
      const updatedProducts = existingProduct ? state.products : [...state.products, sku];
      const newTotalPrice = calculateTotalPrice(state.totalPrice, newProduct.productPrice ?? 0);
      trackBagProgress("bagProgressed", {
        action: existingProduct
          ? TGTGBagProgressAction.ProductQuantityUpdated
          : TGTGBagProgressAction.ProductAdded,
        bag_id: state.bagId!,
        product_sku: getValidSku(sku),
        ean: newProduct.ean,
        quantity: newQuantity,
        method: existingProduct ? null : method,
        product_name: productName,
        product_price: productPrice,
      });

      return {
        ...state,
        productsMap: updatedProductsMap,
        products: updatedProducts,
        totalPrice: newTotalPrice,
      };
    }),
  setProductToAdd: (product) => set({ productToAdd: product }),
  setShowProductNotListedModal: (isOpen) => set({ showProductNotListedModal: isOpen }),
  increaseQuantity: (sku, trackBagProgress) =>
    set((state) => {
      const product = state.productsMap[sku];
      if (!product) return state;
      const newTotalPrice = calculateTotalPrice(state.totalPrice, product.productPrice ?? 0);
      const newQuantity = product.quantity + 1;
      const updatedProductsMap = { ...product, quantity: newQuantity };
      trackBagProgress("bagProgressed", {
        action: TGTGBagProgressAction.ProductQuantityUpdated,
        bag_id: state.bagId!,
        product_sku: getValidSku(sku),
        ean: product.ean,
        quantity: newQuantity,
        method: null,
        product_name: product.productName!,
        product_price: product.productPrice!,
      });
      return {
        ...state,
        productsMap: { ...state.productsMap, [sku]: updatedProductsMap },
        totalPrice: newTotalPrice,
      };
    }),
  decreaseQuantity: (sku, trackBagProgress) =>
    set((state) => {
      const product = state.productsMap[sku];
      if (!product) return state;
      const newTotalPrice = calculateTotalPrice(state.totalPrice, -(product.productPrice ?? 0));
      if (product.quantity === 1) {
        const { [sku]: removedProduct, ...updatedProductsMap } = state.productsMap;
        const updatedProducts = state.products.filter((id) => id !== sku);
        trackBagProgress("bagProgressed", {
          action: TGTGBagProgressAction.ProductRemoved,
          bag_id: state.bagId!,
          product_sku: getValidSku(sku),
          ean: product.ean,
          quantity: 0,
          method: null,
          product_name: product.productName!,
          product_price: product.productPrice!,
        });
        return {
          ...state,
          productsMap: updatedProductsMap,
          products: updatedProducts,
          totalPrice: newTotalPrice,
        };
      }
      const newQuantity = product.quantity - 1;
      const updatedProductsMap = { ...product, quantity: newQuantity };
      trackBagProgress("bagProgressed", {
        action: TGTGBagProgressAction.ProductQuantityUpdated,
        bag_id: state.bagId!,
        product_sku: getValidSku(sku),
        ean: product.ean,
        quantity: newQuantity,
        method: null,
        product_name: product.productName!,
        product_price: product.productPrice!,
      });
      return {
        ...state,
        productsMap: { ...state.productsMap, [sku]: updatedProductsMap },
        totalPrice: newTotalPrice,
      };
    }),
  reset: () => set(initialState),
}));
