import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";

export type ShelfAssignmentSkipConfirmationModalProps = Pick<
  YesNoModalProps,
  "onClickYes" | "onClickNo"
>;

export function ShelfAssignmentSkipConfirmationModal({
  onClickYes,
  onClickNo,
}: ShelfAssignmentSkipConfirmationModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.shelf-assignment-skip-confirmation-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.shelf-assignment-skip-confirmation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "generic.actions.skip",
      })}
      noLabel={intl.formatMessage({
        id: "generic.actions.go-back",
      })}
      isGrey
    />
  );
}
