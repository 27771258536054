import { forwardRef, useCallback, useImperativeHandle, useRef } from "react";

import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import { CloseIcon, ScanIcon } from "ui/Icons/Icons";
import { BodyS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useScannableInput } from "../hooks/useScannableInput";

type ScannableInputProps = {
  onValueChange?: (value: string) => void;
  onScan?: (value: string) => void;
  onKeyboardChange?: (value: string) => void;
  inputMode?: "text" | "numeric";
  inputName?: string;
  isAutoFocus?: boolean;
  showKeyBoard?: boolean;
  isResetable?: boolean;
  placeholder?: string;
  error?: string;
  defaultValue?: string;
  preventKeyboard?: boolean;
};
export type ScannableInputHandle = {
  setValue: (value: string) => void;
  resetValue: () => void;
  focus: () => void;
  value?: string;
};
export const ScannableInput = forwardRef(
  (
    {
      onValueChange = () => {},
      onScan = () => {},
      onKeyboardChange = () => {},
      isAutoFocus = false,
      showKeyBoard = false,
      isResetable = true,
      inputMode,
      placeholder,
      inputName,
      error,
      preventKeyboard = false,
    }: ScannableInputProps,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { inputProps, canReset, resetValue, updateValue } = useScannableInput(inputRef, {
      onValueChange,
      onScan,
      onKeyboardChange,
      inputMode,
      isResetable,
      isAutoFocus,
      showKeyBoard,
      inputName,
      preventKeyboard,
    });
    useImperativeHandle(ref, () => ({
      setValue: updateValue,
      resetValue,
      value: inputProps.value,
      focus: () => inputRef.current?.focus(),
    }));

    const onClickCloseIcon = useCallback(() => {
      resetValue();
      inputRef.current?.focus();
    }, [resetValue]);

    return (
      <FormControl isInvalid={isNotNullNorUndefined(error)}>
        <InputGroup size="lg" marginTop="s300">
          <Input
            ref={inputRef}
            {...inputProps}
            autoComplete="off"
            placeholder={placeholder}
            data-testid="input-scannable-input"
          />
          <InputRightElement>
            {canReset ? <CloseIcon onClick={onClickCloseIcon} /> : <ScanIcon />}
          </InputRightElement>
        </InputGroup>
        {error && (
          <FormErrorMessage>
            <BodyS fontWeight="500" color="error">
              {error}
            </BodyS>
          </FormErrorMessage>
        )}
      </FormControl>
    );
  },
);
