import { ReactElement } from "react";

import {
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";

import { Button, PrimaryButtonProps } from "ui/Button/Button";
import { HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

export type CtaModalProps = {
  title?: string;
  body: ReactElement;
  headerIcon?: ReactElement;
  ctaLabel: string;
  onClickCta: () => void;
  hideCloseButton?: boolean;
  flinkVariant?: PrimaryButtonProps["flinkVariant"];
} & Pick<ModalProps, "onClose" | "isOpen">;

export function CtaModal({
  isOpen = false,
  onClose,
  title,
  body,
  ctaLabel,
  onClickCta,
  hideCloseButton = false,
  headerIcon,
  flinkVariant = "primary",
}: CtaModalProps) {
  return (
    <Modal
      trapFocus={false}
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay bg="rgba(0,0,0,0.2)" />
      <ModalContent marginX="s300">
        {headerIcon && (
          <Circle mt="s300" size="120px" marginX="auto" bg="grey.100">
            {headerIcon}
          </Circle>
        )}
        {isNotNullNorUndefined(title) && (
          <ModalHeader textAlign="center" paddingX="s200">
            <HeaderS>{title}</HeaderS>
          </ModalHeader>
        )}
        {!hideCloseButton && <ModalCloseButton size="lg" />}
        <ModalBody paddingY="0" paddingX="s200">
          {body}
        </ModalBody>
        <ModalFooter padding="s200">
          <Button
            onClick={onClickCta}
            width="full"
            flinkVariant={flinkVariant}
            size="lg"
            data-testid="ctaButton"
          >
            {ctaLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
