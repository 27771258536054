// This function calculates a selectable date for the product's best before date (BBD).
// It takes a number of days from the current date and returns the resulting date,
// which can be used as a boundary (min or max) in a date picker.

export const BBD_CONFIG = {
  MIN_DAYS: 0,
  MAX_DAYS: 100,
};

export function getSelectableBbdDate(daysOffset?: number | null) {
  const offset = daysOffset ?? BBD_CONFIG.MIN_DAYS;
  const date = new Date();
  date.setDate(date.getDate() + offset);
  return date;
}
