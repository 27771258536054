import create from "zustand";

type PickingUIStore = {
  shouldShowOrderCompletionWarning: boolean;
};

type PickingUIStoreWithMethods = PickingUIStore & {
  setPickingUIState(pickingUIState: Partial<PickingUIStore>): void;
};

const initialState: PickingUIStore = {
  shouldShowOrderCompletionWarning: false,
};

export const usePickingUIStore = create<PickingUIStoreWithMethods>()((set) => ({
  ...initialState,
  setPickingUIState(pickingUIState) {
    set(pickingUIState);
  },
}));
