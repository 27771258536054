import { useCallback, useEffect, useMemo } from "react";

import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import {
  useFetchNonScannableCategories,
  useLetterRanges,
  useSafeSelectedLetterRangeId,
} from "shared/stores/useProductSearchService";
import { useProductSearchStore } from "shared/stores/useProductSearchStore";
import { LetterRangeSelector } from "ui/LetterRangeSelector/LetterRangeSelector";
import { SubcategoryList } from "ui/SubcategoryList/SubcategoryList";

export function SubcategoriesProductsSearch() {
  const navigate = useNavigate();
  const { currentLetterRangeId, setCurrentLetterRangeId, setSelectedSubcategory } =
    useProductSearchStore((state) => ({
      currentLetterRangeId: state.currentLetterRangeId,
      setCurrentLetterRangeId: state.setCurrentLetterRangeId,
      setSelectedSubcategory: state.setSelectedSubcategory,
    }));
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { categories: groupedSubcategories } = useFetchNonScannableCategories();

  const letterRanges = useLetterRanges(groupedSubcategories || {});
  const safeSelectedLetterRangeId = useSafeSelectedLetterRangeId(
    letterRanges,
    currentLetterRangeId,
  );

  const selectLetterRangeId = useCallback(
    (letterRangeId: string) => setCurrentLetterRangeId(letterRangeId),
    [setCurrentLetterRangeId],
  );

  useEffect(() => {
    if (safeSelectedLetterRangeId !== currentLetterRangeId) {
      selectLetterRangeId(safeSelectedLetterRangeId);
    }
  }, [currentLetterRangeId, safeSelectedLetterRangeId, selectLetterRangeId]);

  const displayedSubcategories = useMemo(
    () => groupedSubcategories?.[safeSelectedLetterRangeId] || [],
    [groupedSubcategories, safeSelectedLetterRangeId],
  );

  const handleSubcategoryClick = useCallback(
    (subcategoryID: string, subcategoryName?: string) => {
      setTimeout(() => {
        setSelectedSubcategory({ subcategoryID, subcategoryName });
        navigate(routes.inventory.tooGoodToGo.noEANProducts);
      }, 90);
    },
    [navigate, setSelectedSubcategory],
  );

  return (
    <>
      <LetterRangeSelector
        p="s200"
        pt="0"
        value={safeSelectedLetterRangeId}
        letterRanges={letterRanges}
        onInput={(v) => selectLetterRangeId(v as string)}
      />
      <SubcategoryList
        subcategories={displayedSubcategories}
        onClickSubcategory={handleSubcategoryClick}
        lang={appLanguage}
        bgColor="white"
        pt="s300"
        overflowY="scroll"
      />
    </>
  );
}
