import { useCallback, useEffect, useMemo } from "react";

import { Flex, useDisclosure } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { unstable_useBlocker as useBlocker } from "react-router-dom";
import shallow from "zustand/shallow";

import { PageName, TGTGBagPreparationFeatures, TGTGBagUpdateStates } from "analytics/events";
import { routes } from "config/routes";
import { DiscardBagConfirmationModal } from "flows/Inbound/components/DiscardBagConfirmationModal";
import { ProductsSearchComponent } from "flows/Inbound/components/ProductsSearchComponent";
import { ScanPlaceholder } from "flows/Inventory/shared/components/ScanPlaceholder";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useScan } from "shared/hooks/useScan/useScan";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { IntlMessageId } from "shared/types/lang";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";

import { EndPreparationButton } from "../components/EndPreparationButton";
import { OverGoalWarningModal } from "../components/OverGoalWarningModal";
import { OverMaximumWarningModal } from "../components/OverMaximumWarningModal";
import { ProductInfoCard } from "../components/ProductInfoCard";
import ProductNotListedModal from "../components/ProductNotListedModal";
import { useFetchProductsByEanWithPrice } from "../hooks/useFetchProductsByEan";
import { useSurpriseBagMutation } from "../hooks/useSurpriseBagMutation";
import { useTGTGBagStore } from "../stores/useTGTGBagStore";
import { isOverPriceLimit, PRICE_LIMIT_THRESHOLD } from "../utils/helpers";

export function TGTGPrepareBagPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { sendSegmentTrackEvent } = useAnalytics();

  const {
    isOpen: isDiscardBagConfirmationModalOpen,
    onOpen: openDiscardBagConfirmationModal,
    onClose: closeDiscardBagConfirmationModal,
  } = useDisclosure();
  const {
    isOpen: isOverGoalWarningModalOpen,
    onOpen: openOverGoalWarningModal,
    onClose: closeOverGoalWarningModal,
  } = useDisclosure();
  const {
    isOpen: isOverMaximumWarningModalOpen,
    onOpen: openOverMaximumWarningModal,
    onClose: closeOverMaximumWarningModal,
  } = useDisclosure();

  const {
    isInhouse,
    bagId,
    bagKey,
    bagSku,
    price,
    totalPrice,
    products,
    productsMap,
    showProductNotListedModal,
    reset,
  } = useTGTGBagStore(
    (state) => ({
      isInhouse: state.isInhouse,
      bagId: state.bagId,
      bagKey: state.bagKey,
      bagSku: state.bagSku,
      price: state.price,
      totalPrice: state.totalPrice,
      products: state.products,
      productsMap: state.productsMap,
      showProductNotListedModal: state.showProductNotListedModal,
      reset: state.reset,
    }),
    shallow,
  );

  useEffect(() => {
    if (!bagKey) {
      navigate(routes.inventory.tooGoodToGo.root);
    }
  }, [bagKey, navigate]);

  const overMaximum = useMemo(() => isOverPriceLimit(price, totalPrice), [price, totalPrice]);

  useEffect(() => {
    if (overMaximum) {
      openOverMaximumWarningModal();
    }
  }, [openOverMaximumWarningModal, overMaximum]);

  useEffect(() => {
    if (isOverMaximumWarningModalOpen) {
      sendSegmentTrackEvent("inAppMessageShown", {
        screen_name: PageName.BAG_PREPARATION,
        component_name: "bag_over_maximum",
        component_content: bagId!,
      });
    }
  }, [bagId, isOverMaximumWarningModalOpen, sendSegmentTrackEvent]);

  const { onScan, isScannedProductLoading } = useFetchProductsByEanWithPrice();
  const setSearchState = useProductSearchStore((state) => state.setSearchState);

  const blockerFunction = (args: any) =>
    products.length > 0 && !args.nextLocation.pathname.includes("/inventory/too-good-to-go");
  const blocker = useBlocker(blockerFunction);
  const handleConfirmNavigation = () => {
    if (isDiscardBagConfirmationModalOpen) {
      closeDiscardBagConfirmationModal();
      navigate(routes.inventory.tooGoodToGo.root);
    } else {
      blocker.proceed?.();
    }
    reset();
  };
  const handleCancelNavigation = () => {
    blocker.reset?.();
    closeDiscardBagConfirmationModal();
  };
  const activateSearchFlow = useCallback(() => {
    setSearchState(ProductSearchActionTypes.ACTIVATE_SEARCH);
    navigate(routes.inventory.tooGoodToGo.selectProduct);
  }, [navigate, setSearchState]);

  useScan({ onScan });

  const calculatePrices = useMemo(() => {
    const bagPrice = Math.round((price ?? 0) * 100) / 100;
    const currentTotalPrice = Math.round(totalPrice * 100) / 100;
    return { bagPrice, currentTotalPrice };
  }, [price, totalPrice]);

  const { updateSurpriseBagStock, loading } = useSurpriseBagMutation();
  const handleBagState = useCallback(
    (isOverGoal: boolean) => {
      const { bagPrice, currentTotalPrice } = calculatePrices;

      if (isOverGoal) {
        openOverGoalWarningModal();
        sendSegmentTrackEvent("inAppMessageShown", {
          screen_name: PageName.BAG_PREPARATION,
          component_name: "bag_over_the_goal",
          component_content: bagId!,
        });
        return;
      }

      if (isInhouse) {
        if (loading) return;
        updateSurpriseBagStock({
          bagId,
          bagSku,
          bagKey,
          productsMap,
          calculatePrices,
        });
      } else {
        sendSegmentTrackEvent("bagStateUpdated", {
          state: TGTGBagUpdateStates.BagPreparationFinished,
          bag_id: bagId!,
          bag_value_final: currentTotalPrice,
          bag_value_target: bagPrice,
          bag_type: bagKey!,
          bag_value_limit: bagPrice + PRICE_LIMIT_THRESHOLD,
          bag_preparation_feature: TGTGBagPreparationFeatures.TgtgAssistant,
        });
      }
      navigate(routes.inventory.tooGoodToGo.confirmation);
    },
    [
      bagId,
      bagKey,
      bagSku,
      calculatePrices,
      isInhouse,
      loading,
      navigate,
      openOverGoalWarningModal,
      productsMap,
      sendSegmentTrackEvent,
      updateSurpriseBagStock,
    ],
  );

  const handleEndPreparationButton = useCallback(() => {
    const THRESHOLD = 2;
    const { bagPrice, currentTotalPrice } = calculatePrices;
    const targetPrice = bagPrice + THRESHOLD;
    const isOverGoal = currentTotalPrice > targetPrice;
    handleBagState(isOverGoal);
  }, [calculatePrices, handleBagState]);

  const handleOverGoalWarningConfirm = useCallback(() => {
    handleBagState(false);
    closeOverGoalWarningModal();
  }, [closeOverGoalWarningModal, handleBagState]);

  return (
    <Page isFull isBgGrey h="100%" justifyContent="flex-start">
      <NavigationHeader
        title={intl.formatMessage({
          id: `pages.tgtg-bag-selection-page.bag-types.${bagKey}` as IntlMessageId,
        })}
        onClickGoBack={() =>
          products.length > 0
            ? openDiscardBagConfirmationModal()
            : navigate(routes.inventory.tooGoodToGo.root)
        }
      />
      <ProductsSearchComponent
        isSearchFlowActive={false}
        activateTextSearch={activateSearchFlow}
        showUnitsToggle={false}
      />
      {!products.length ? (
        <ScanPlaceholder
          title="pages.tgtg-bag-selection-page.scan-placeholder.title"
          subtitle="pages.tgtg-bag-selection-page.scan-placeholder.subtitle"
          justifyContent="center"
          flexGrow="1"
          px="s400"
        />
      ) : (
        <Flex
          direction="column"
          w="100%"
          p="s100"
          marginBottom="72px"
          gap="s200"
          pb="s300"
          overflow="scroll"
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {products.map((productSku: string) => (
            <ProductInfoCard key={productSku} productSku={productSku} />
          ))}
        </Flex>
      )}
      <EndPreparationButton
        bagOfPrice={price ?? 0}
        totalPrice={totalPrice}
        onClickButton={handleEndPreparationButton}
      />
      <DiscardBagConfirmationModal
        isOpen={blocker.state === "blocked" || isDiscardBagConfirmationModalOpen}
        onClickYes={handleConfirmNavigation}
        onClickNo={handleCancelNavigation}
      />
      <ProductNotListedModal isOpen={showProductNotListedModal} />
      {isOverGoalWarningModalOpen && (
        <OverGoalWarningModal
          onClickYes={handleOverGoalWarningConfirm}
          onClickNo={closeOverGoalWarningModal}
        />
      )}
      {isOverMaximumWarningModalOpen && (
        <OverMaximumWarningModal onClose={closeOverMaximumWarningModal} />
      )}
      <SpinnerModal isOpen={isScannedProductLoading} />
    </Page>
  );
}
