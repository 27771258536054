import { useMemo, useRef } from "react";

import { Box } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import {
  RestockingItemStatus,
  TranslatedRestockingItem,
} from "flows/Inventory/flows/RestockingList/models/restockingItem/types";
import useSwipeScroll from "shared/hooks/useSwipeScroll";
import { TrashIcon } from "ui/Icons/Icons";
import { PrivateRestockingListItemCard } from "ui/PrivateRestockingListItemCard/PrivateRestockingListItemCard";
import { SideButton } from "ui/SideButton/SideButton";
import { SwipeScrollWrapper } from "ui/SwipeScrollWrapper";

export function RestockingListItem({
  restockingItem,
  isClickable = false,
  isShowingCompleteCardsAsBlocked = false,
  isShowingSwipeButtons = false,
  isLoading = false,
  scrollContainer = null,
  elementRef = { current: null },
  onClick = () => {},
  onClickRemove = () => {},
  isDisabled,
}: {
  restockingItem: TranslatedRestockingItem;
  isClickable?: boolean;
  isShowingCompleteCardsAsBlocked?: boolean;
  isShowingSwipeButtons?: boolean;
  isLoading?: boolean;
  scrollContainer?: React.RefObject<HTMLDivElement | null> | null;
  elementRef?: React.RefObject<HTMLDivElement>;
  onClick?: () => void;
  onClickRemove?: (sku: string) => void;
  isDisabled?: boolean;
}) {
  const intl = useIntl();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const { handleSwipeScroll } = useSwipeScroll(scrollContainer ?? { current: null }, wrapperRef);

  const translatedLabels = useMemo(
    () => ({
      bioLabel: intl.formatMessage({
        id: "flows.inventory.restocking-list.bio-label",
      }),
      nonBioLabel: intl.formatMessage({
        id: "flows.inventory.restocking-list.non-bio-label",
      }),
      inStockLabel: intl.formatMessage({
        id: "flows.inventory.restocking-list.in-stock-label",
      }),
      backOnShelfLabel: intl.formatMessage({
        id: "flows.inventory.restocking-list.back-on-shelf-label",
      }),
    }),
    [intl],
  );

  const isRestockingComplete = restockingItem.status === RestockingItemStatus.done;
  const isBlocked = isShowingCompleteCardsAsBlocked && isRestockingComplete;

  const mainComponent = (
    <PrivateRestockingListItemCard
      key={restockingItem.sku}
      restockingItem={restockingItem}
      onClickCard={onClick}
      isRestockingComplete={isRestockingComplete}
      isBlocked={isBlocked}
      isClickable={isClickable}
      isLoading={isLoading}
      {...translatedLabels}
      ref={elementRef}
    />
  );

  const swipeScrollButton = (
    <Box flex="0 0 137px">
      <SideButton
        height="100%"
        color="red.500"
        bgColor="red.100"
        icon={<TrashIcon boxSize={6} />}
        label={intl.formatMessage({
          id: "flows.inventory.restocking-list.pages.list-preparation.delete-label",
          defaultMessage: "Delete",
        })}
        onClick={() => onClickRemove(restockingItem.sku)}
        disabled={isDisabled}
        data-testid="delete-product"
      />
    </Box>
  );

  return (
    <SwipeScrollWrapper
      mainComponent={mainComponent}
      handleSwipeScroll={handleSwipeScroll}
      scrolledComponent={swipeScrollButton}
      showSwipeButtons={isShowingSwipeButtons}
      ref={wrapperRef}
    />
  );
}
