import { useCallback } from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { v4 as uuidv4 } from "uuid";

import { TGTGBagPreparationFeatures, TGTGBagUpdateStates } from "analytics/events";
import { routes } from "config/routes";
import { EppoFeatureFlagsWithJson } from "core/types/flags";
import FlinkBagBeverages from "images/random/bag_with_bottles.png";
import FlinkBagFrozenItems from "images/random/bag_with_frozen_items.png";
import FlinkBag from "images/random/flink_bag.png";
import FlinkBagBread from "images/random/flink_bag_bread.png";
import { Page } from "shared/components/Page";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { useFeatureFlagVariation } from "shared/hooks/useEppoFeatureFlagVariation";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { IntlMessageId } from "shared/types/lang";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { BodyS, DetailS, TitleXXS } from "ui/Typography/Typography";

import { useTGTGBagStore } from "../stores/useTGTGBagStore";
import { PRICE_LIMIT_THRESHOLD } from "../utils/helpers";

type BagTypes = Array<{ key: string; minimum_value: number; sku?: string }> | null;
const BAG_IMAGES: Record<string, string> = {
  alcoholic_beverages: FlinkBagBeverages,
  bakery: FlinkBagBread,
  frozen_items: FlinkBagFrozenItems,
};

function Menucard({
  imageUrl,
  title,
  price,
  onClick,
}: {
  imageUrl: string;
  title: string;
  price: number;
  onClick: () => void;
}) {
  const intl = useIntl();
  return (
    <Box
      minW="128px"
      maxW="128px"
      borderRadius="md"
      position="relative"
      overflow="hidden"
      boxShadow="md"
      onClick={onClick}
    >
      <Image src={imageUrl} alt={title} objectFit="cover" w="full" h="full" borderRadius="md" />
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        bg="rgba(248, 248, 248, 0.8)"
        px="s100"
        py="s50"
      >
        <TitleXXS>
          {intl.formatMessage({
            id: `pages.tgtg-bag-selection-page.bag-types.${title}` as IntlMessageId,
          })}
        </TitleXXS>
        <DetailS>
          {intl.formatMessage({ id: "pages.tgtg-bag-selection-page.bag-types.price" }, { price })}
        </DetailS>
      </Box>
    </Box>
  );
}

export function TGTGBagSelectionPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const hubSlug = useHubSlug();
  const response = useFeatureFlagVariation(
    hubSlug!,
    EppoFeatureFlagsWithJson.TOO_GOOD_TO_GO_BAG_TYPES,
  );
  const { sendSegmentTrackEvent } = useAnalytics();

  const bagTypesData = response?.bag_types as BagTypes;
  const isInhouse = !!response?.inhouse;

  const { setSelectedBag } = useTGTGBagStore();
  const handleBagSelect = useCallback(
    (bagKey: string, price: number, sku?: string) => {
      const bagId = uuidv4();
      if (bagId) {
        const bagSku = isInhouse && sku ? sku : null;
        setSelectedBag(isInhouse, bagId, bagKey, price, bagSku);
        sendSegmentTrackEvent("bagStateUpdated", {
          state: TGTGBagUpdateStates.BagPreparationStarted,
          bag_id: bagId,
          bag_value_final: null,
          bag_value_target: price,
          bag_type: bagKey,
          bag_value_limit: price + PRICE_LIMIT_THRESHOLD,
          bag_preparation_feature: TGTGBagPreparationFeatures.TgtgAssistant,
        });
        navigate(routes.inventory.tooGoodToGo.prepareBag);
      }
    },
    [isInhouse, navigate, sendSegmentTrackEvent, setSelectedBag],
  );

  return (
    <Page isFull isBgGrey h="100%" justifyContent="flex-start">
      <NavigationHeader
        title={intl.formatMessage({ id: "pages.tgtg-bag-selection-page.title" })}
        onClickGoBack={() => navigate(routes.inventory.root)}
      />
      <Flex
        direction="column"
        align="center"
        p="s300"
        gap="s200"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <BodyS>{intl.formatMessage({ id: "pages.tgtg-bag-selection-page.sub-title" })}</BodyS>
        {bagTypesData && (
          <Flex maxW="280px" wrap="wrap" columnGap="s300" rowGap="s200" justify="flex-start">
            {bagTypesData.map(({ key, minimum_value, sku }) => (
              <Menucard
                key={key}
                imageUrl={BAG_IMAGES[key] || FlinkBag}
                title={key}
                price={minimum_value}
                onClick={() => handleBagSelect(key, minimum_value, sku)}
              />
            ))}
          </Flex>
        )}
      </Flex>
    </Page>
  );
}
