import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";

export type DeleteListConfirmationModalProps = Pick<YesNoModalProps, "onClickYes" | "onClickNo">;

export function DeleteListConfirmationModal({
  onClickYes,
  onClickNo,
}: DeleteListConfirmationModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.delete-list-confirmation-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.delete-list-confirmation-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "generic.actions.delete",
      })}
      noLabel={intl.formatMessage({
        id: "generic.actions.cancel",
      })}
      isGrey
    />
  );
}
