import { useMemo } from "react";

import { AppLanguage, useAppLanguageStore } from "core/stores/useAppLanguageStore";

import German from "../../../i18n/de.json";
import English from "../../../i18n/en.json";

export function useLocalizedMessages() {
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);

  const messages = useMemo(() => {
    return {
      [AppLanguage.De]: German,
      [AppLanguage.En]: English,
    }[appLanguage];
  }, [appLanguage]);

  return messages;
}
