import { useCallback, useMemo, useState } from "react";

import { Button, Flex } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router";
import shallow from "zustand/shallow";

import { TGTGBagAdditionMethod } from "analytics/events";
import { routes } from "config/routes";
import { useAppLanguageStore } from "core/stores/useAppLanguageStore";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { TranslatedProductWithPrice } from "shared/models/products/types";
import {
  ProductSearchActionTypes,
  useProductSearchStore,
} from "shared/stores/useProductSearchStore";
import { DropDown } from "ui/DropDown/DropDown";
import { InboundNoEANProductCard } from "ui/InboundNoEANProductCard/InboundNoEANProductCard";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { isNullOrUndefined } from "utils/tsHelpers";

import { useFetchProductsBySubcategory } from "../hooks/useFetchProductsBySubcategory";
import { useTGTGBagStore } from "../stores/useTGTGBagStore";

const CATEGORIES_WITH_COOS = ["Fruits", "Vegetables"];

export function TGTGSelectNoEANProductPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const appLanguage = useAppLanguageStore((state) => state.appLanguage);
  const { sendSegmentTrackEvent } = useAnalytics();

  const { selectedCategoryName, selectedSubcategoryName, setSearchState } = useProductSearchStore(
    (state) => ({
      selectedCategoryName: state.selectedCategoryName,
      selectedSubcategoryName: state.selectedSubcategoryName,
      setSearchState: state.setSearchState,
    }),
    shallow,
  );

  const { products, isLoading: areProductsLoading } = useFetchProductsBySubcategory();
  const goBackToSubcategories = useCallback(() => {
    setSearchState(ProductSearchActionTypes.ACTIVATE_CATEGORY_SEARCH);
    navigate(routes.inventory.tooGoodToGo.selectProduct);
  }, [navigate, setSearchState]);

  const showCountryFilter = useMemo(
    () => CATEGORIES_WITH_COOS.includes(selectedCategoryName ?? ""),
    [selectedCategoryName],
  );

  const shouldShowSpinnerModal = areProductsLoading || isNullOrUndefined(products);
  const { addProduct, setProductToAdd, setShowProductNotListedModal } = useTGTGBagStore(
    (state) => ({
      addProduct: state.addProduct,
      setProductToAdd: state.setProductToAdd,
      setShowProductNotListedModal: state.setShowProductNotListedModal,
    }),
  );
  const onClickProductCard = useCallback(
    (product: TranslatedProductWithPrice) => {
      if (product.productPrice === null) {
        setProductToAdd(product);
        setShowProductNotListedModal(true);
      } else {
        addProduct(product, TGTGBagAdditionMethod.NoEANSearched, sendSegmentTrackEvent);
      }
      navigate(routes.inventory.tooGoodToGo.prepareBag);
    },
    [addProduct, navigate, sendSegmentTrackEvent, setProductToAdd, setShowProductNotListedModal],
  );

  const translatedLabels = {
    bioLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.bio-label",
    }),
    nonBioLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.non-bio-label",
    }),
    addHandlingUnitLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.add-handling-unit-label",
    }),
    addSingleUnitLabel: intl.formatMessage({
      id: "components.inbound.search-flow.no-ean-products.add-single-unit-label",
    }),
  };
  const bioFilterLabelBio = intl.formatMessage({
    id: "components.inbound.search-flow.no-ean-products.bio-filter.bio",
  });
  const bioFilterLabelNonBio = intl.formatMessage({
    id: "components.inbound.search-flow.no-ean-products.bio-filter.non-bio",
  });

  const [bioFilter, setBioFilter] = useState<string | null>(null);
  const [countryFilter, setCountryFilter] = useState<string | null>(null);

  const productMatchesBioFilter = useCallback(
    (p: TranslatedProductWithPrice) =>
      isNullOrUndefined(bioFilter) || (bioFilter === "bio") === p.isBio,
    [bioFilter],
  );

  const productMatchesCountryFilter = useCallback(
    (p: TranslatedProductWithPrice) =>
      isNullOrUndefined(countryFilter) || countryFilter === p.countryOfOriginName,
    [countryFilter],
  );

  const productsFilteredbyBio = useMemo(
    () => products?.filter(productMatchesBioFilter),
    [products, productMatchesBioFilter],
  );

  const productsFilteredbyCountry = useMemo(
    () => products?.filter(productMatchesCountryFilter),
    [products, productMatchesCountryFilter],
  );

  const productsFiltered = useMemo(
    () =>
      products?.filter((p) => {
        return productMatchesBioFilter(p) && productMatchesCountryFilter(p);
      }),
    [products, productMatchesBioFilter, productMatchesCountryFilter],
  );

  const productsFilteredAndSorted = useMemo(
    () => sortBy(productsFiltered, (product) => product.productName),
    [productsFiltered],
  );

  const bioFilterOptions = useMemo(
    () =>
      [
        { key: "bio", label: bioFilterLabelBio },
        { key: "nonbio", label: bioFilterLabelNonBio },
      ].filter(({ key }) => productsFilteredbyCountry?.find((p) => p.isBio === (key === "bio"))),
    [bioFilterLabelBio, bioFilterLabelNonBio, productsFilteredbyCountry],
  );

  const countryFilterOptions = useMemo(() => {
    const setOfCountryNames =
      productsFilteredbyBio?.reduce((countries, product) => {
        const countryOfOrigin = product.countryOfOriginName;
        if (countryOfOrigin) {
          countries.add(countryOfOrigin);
        }
        return countries;
      }, new Set<string>()) || new Set<string>();
    return Array.from(setOfCountryNames)
      .sort(new Intl.Collator(appLanguage).compare)
      .map((countryName) => ({
        key: countryName,
        label: countryName,
      }));
  }, [productsFilteredbyBio, appLanguage]);

  const resetFilters = useCallback(() => {
    setBioFilter(null);
    setCountryFilter(null);
  }, []);

  return (
    <Page pos="relative" isFull isBgGrey data-testid="inbound-no-ean-products-page">
      <Flex position="sticky" top="0" zIndex="2" direction="column" width="100%">
        <NavigationHeader
          title={selectedSubcategoryName || ""}
          onClickGoBack={goBackToSubcategories}
        />
        <Flex pt="1.25rem" pb="s200" pl="1.25rem" justify="space-between" bgColor="grey.100">
          <Flex gap="s150">
            <DropDown options={bioFilterOptions} value={bioFilter} onInput={setBioFilter}>
              <FormattedMessage id="components.inbound.search-flow.no-ean-products.bio-filter.label" />
            </DropDown>
            {showCountryFilter && (
              <DropDown
                options={countryFilterOptions}
                value={countryFilter}
                onInput={setCountryFilter}
              >
                <FormattedMessage id="components.inbound.search-flow.no-ean-products.country-filter.label" />
              </DropDown>
            )}
          </Flex>
          <Button
            onClick={resetFilters}
            variant="link"
            color="grey.600"
            _hover={{
              textDecoration: "none",
            }}
            _active={{
              textDecoration: "none",
            }}
            pr="s200"
            pl="s200"
          >
            <FormattedMessage id="components.inbound.search-flow.no-ean-products.reset-filters" />
          </Button>
        </Flex>
      </Flex>
      <SpinnerModal isOpen={shouldShowSpinnerModal} />
      <Flex direction="column" gap="s200">
        {!shouldShowSpinnerModal &&
          productsFilteredAndSorted.map((p) => {
            const productCardProps = {
              productName: p.productName,
              productImageUrl: p.productImageUrl,
              productSku: p.productSku,
              isProductBio: !!p.isBio,
              countryOfOriginName: p.countryOfOriginName,
              onClickProductCard: () => onClickProductCard(p),
              ...translatedLabels,
            };
            return <InboundNoEANProductCard key={p.productSku} {...productCardProps} />;
          })}
      </Flex>
      <SpinnerModal isOpen={shouldShowSpinnerModal} />
    </Page>
  );
}
