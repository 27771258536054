import { useMemo } from "react";

import { Image } from "@chakra-ui/react";

import FlinkLogoSmall from "images/logos/Flink-logo-small.png";
import JustEatReweLogo from "images/logos/Just-Eat-Rewe-logo.png";
import JustEatReweReadyLogo from "images/logos/Just-reweready.png";
import JustEatWeinfreundeLogo from "images/logos/Just-weinfreunde.png";
import UberDirectLogo from "images/logos/Uber-direct-logo.png";
import WoltDriveLogo from "images/logos/Wolt-drive-logo.png";
import { JET_REWE_RESELLERS } from "shared/constants";

export function DeliveryLogo({
  externalDeliveryProvider,
  reseller,
}: {
  externalDeliveryProvider?: string | null;
  reseller?: string | null;
}) {
  const deliveryLogo = useMemo(() => {
    const deliveryLogoImg =
      reseller && JET_REWE_RESELLERS.includes(reseller) ? reseller : externalDeliveryProvider;
    switch (deliveryLogoImg) {
      case "UBER-DIRECT":
        return UberDirectLogo;
      case "WOLT-DRIVE":
        return WoltDriveLogo;
      case "JUST-EAT-REWE":
        return JustEatReweLogo;
      case "JUST-EAT-WEINFREUNDE":
        return JustEatWeinfreundeLogo;
      case "JUST-EAT-REWEREADY":
        return JustEatReweReadyLogo;
      default:
        return FlinkLogoSmall;
    }
  }, [externalDeliveryProvider, reseller]);

  if (!deliveryLogo) return null;

  return (
    <Image
      role="img"
      alt="logo-supplier"
      src={deliveryLogo}
      height="28px"
      width="auto"
      loading="lazy"
      data-testid="logo-supplier"
    />
  );
}
