import { useCallback, useEffect, useMemo, useState } from "react";

import { IconButton } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import { DeleteFinalItemConfirmationModal } from "flows/Inbound/components/DeleteFinalItemConfirmationModal";
import { InboundDroppingList } from "flows/Inbound/components/InboundDroppingList";
import { RefetchClaimedListConfirmationModal } from "flows/Inbound/components/RefetchClaimedListConfirmationModal";
import { UnclaimedListConfirmationModal } from "flows/Inbound/components/UnclaimedListConfirmationModal";
import { UnclaimListConfirmationModal } from "flows/Inbound/components/UnclaimListConfirmationModal";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import {
  useDeleteClaimedProduct,
  useFetchClaimedProducts,
  useUnClaimInboundList,
} from "flows/Inbound/stores/useInboundDropService";
import { Page } from "shared/components/Page";
import { SpinnerModal } from "shared/components/SpinnerModal";
import { AscDscIcon } from "ui/AscDscIcon/AscDscIcon";
import { Banner } from "ui/Banner/Banner";
import { TapIcon } from "ui/Icons/Icons";
import { NavigationHeader } from "ui/NavigationHeader/NavigationHeader";
import { transformChunks } from "utils/ui";

export function InboundDroppingListPage() {
  const navigate = useNavigate();
  const [isBannerVisible, setBannerVisible] = useState(true);

  const { isFetchingClaimedProducts } = useFetchClaimedProducts();
  const { unClaimInboundList, isUnClaimingList } = useUnClaimInboundList();
  const { deleteClaimedProduct } = useDeleteClaimedProduct();

  const {
    droppingListId,
    droppingListName,
    droppingListShelfSortOrder,
    claimedProductsSortedByShelf,
    claimedProductsStockUpdates,
    inboundUIState,
    toggleDroppingListShelfSortOrder,
    setInboundUIState,
  } = useInboundStore((state) => ({
    droppingListId: state.droppingListId,
    droppingListName: state.droppingListName || "",
    droppingListShelfSortOrder: state.droppingListShelfSortOrder,
    claimedProductsSortedByShelf: state.claimedProductsSortedByShelf,
    claimedProductsStockUpdates: state.claimedProductsStockUpdates,
    inboundUIState: state.inboundUIState,
    toggleDroppingListShelfSortOrder: state.toggleDroppingListShelfSortOrder,
    setInboundUIState: state.setInboundUIState,
  }));

  useEffect(() => {
    if (!droppingListId) navigate(routes.inbound.root);
  }, [droppingListId, navigate]);

  const droppingListInboundUnitsSkusSortedByShelf = useMemo(
    () => claimedProductsSortedByShelf.map((p) => p.sku),
    [claimedProductsSortedByShelf],
  );

  const isDroppingComplete = useMemo(
    () =>
      Object.values(claimedProductsStockUpdates).every(
        (stockUpdateState) => !!stockUpdateState.stockUpdated,
      ),
    [claimedProductsStockUpdates],
  );

  const onClickGoBack = useCallback(() => {
    if (isDroppingComplete) {
      navigate(routes.inbound.root);
    }
    setInboundUIState({ isUnclaimListConfirmationModalVisible: true });
  }, [isDroppingComplete, navigate, setInboundUIState]);

  const handleTapOnCardBanner = () => setBannerVisible(false);

  const showTapOnCardAlert = isBannerVisible && !isDroppingComplete;

  return (
    <Page isFull isBgGrey height="100%">
      <NavigationHeader
        onClickGoBack={onClickGoBack}
        title={droppingListName}
        rightElement={
          <IconButton
            size="xs"
            variant="ghost"
            aria-label="Change sort order"
            icon={<AscDscIcon height="24px" width="24px" sortState={droppingListShelfSortOrder} />}
            onClick={toggleDroppingListShelfSortOrder}
          />
        }
      />
      {showTapOnCardAlert && (
        <Banner
          colorVariant="purple"
          icon={<TapIcon />}
          subTitle={
            <FormattedMessage
              id="components.inbound.inbound-units-list.banner"
              values={transformChunks({ b: { as: "strong", color: "black" } })}
            />
          }
          onClose={handleTapOnCardBanner}
        />
      )}
      <InboundDroppingList
        isDroppingComplete={isDroppingComplete}
        droppingListInboundUnitsSkusSortedByShelf={droppingListInboundUnitsSkusSortedByShelf}
        handleTapOnCardBanner={handleTapOnCardBanner}
      />
      <UnclaimListConfirmationModal
        isOpen={inboundUIState.isUnclaimListConfirmationModalVisible}
        onClickYes={() => {
          if (droppingListId) unClaimInboundList(droppingListId);
        }}
        onClickNo={() => setInboundUIState({ isUnclaimListConfirmationModalVisible: false })}
      />
      <DeleteFinalItemConfirmationModal
        isOpen={!!inboundUIState.isDeleteFinalItemModalVisibleForSku}
        onClickYes={() => {
          if (droppingListId && inboundUIState.isDeleteFinalItemModalVisibleForSku)
            deleteClaimedProduct(
              droppingListId,
              inboundUIState.isDeleteFinalItemModalVisibleForSku,
            );
          navigate(routes.inbound.root);
        }}
        onClickNo={() => setInboundUIState({ isDeleteFinalItemModalVisibleForSku: null })}
      />
      {inboundUIState.isUnclaimedListConfirmationModalVisible && <UnclaimedListConfirmationModal />}
      {inboundUIState.isRefetchClaimedListConfirmationModalVisible && (
        <RefetchClaimedListConfirmationModal />
      )}
      <SpinnerModal isOpen={isFetchingClaimedProducts || isUnClaimingList} />
    </Page>
  );
}
