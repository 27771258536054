import { useCallback } from "react";

import { Box, Center, Flex, Image } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";

import { routes } from "config/routes";
import TGTGBag from "images/random/tgtg-bag.png";
import TGTGFlinkBag from "images/random/tgtg-flink-bag.png";
import { Page } from "shared/components/Page";
import { Button } from "ui/Button/Button";
import { BodyM, HeaderS } from "ui/Typography/Typography";

import { useTGTGBagStore } from "../stores/useTGTGBagStore";

export function TGTGBagConfirmationPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const { isInhouse, reset } = useTGTGBagStore();
  const { logoSrc, logoBg } = isInhouse
    ? {
        logoSrc: TGTGFlinkBag,
        logoBg: "pinkFlink.100",
      }
    : {
        logoSrc: TGTGBag,
        logoBg: "veggie.200",
      };

  const handleEndPreparation = useCallback(() => {
    reset();
    navigate(routes.inventory.tooGoodToGo.root);
  }, [navigate, reset]);
  return (
    <Page isFull isBgGrey isCentered>
      <Center h="100%" w="100%" flexDirection="column" gap="s300">
        <Flex boxSize="124px" borderRadius="full" bg={logoBg} justify="center" align="end">
          <Image src={logoSrc} alt="tgtg-bag" w="109px" h="104px" borderRadius="full" />
        </Flex>
        <Flex direction="column" maxW="280px" gap="s100">
          <HeaderS textAlign="center">
            {intl.formatMessage({ id: "pages.tgtg-bag-confirmation-page.title" })}
          </HeaderS>
          <BodyM textAlign="center">
            {intl.formatMessage({ id: "pages.tgtg-bag-confirmation-page.body" })}
          </BodyM>
        </Flex>
      </Center>
      <Box bg="white" p="s200" w="100%" borderTopWidth="1px" borderColor="grey.200">
        <Button onClick={handleEndPreparation} size="lg" width="100%">
          {intl.formatMessage({ id: "generic.actions.got-it" })}
        </Button>
      </Box>
    </Page>
  );
}
