import { useCallback } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { InventoryChangeReason } from "__graphql__/types";
import { TGTGBagPreparationFeatures, TGTGBagUpdateStates } from "analytics/events";
import { useAnalytics } from "shared/hooks/useAnalytics";

import { useUpdateSurpriseBagStockMutation } from "../queries/surpriseBagStock.generated";
import { ProductsMap } from "../stores/useTGTGBagStore";
import { PRICE_LIMIT_THRESHOLD, transformToProductInput } from "../utils/helpers";

type CalculatePrices = {
  bagPrice: number;
  currentTotalPrice: number;
};

export function useSurpriseBagMutation() {
  const { sendSegmentTrackEvent } = useAnalytics();
  const [updateSurpriseBagStockMutation, { loading }] = useUpdateSurpriseBagStockMutation();
  const updateSurpriseBagStock = useCallback(
    async ({
      bagId,
      bagSku,
      bagKey,
      productsMap,
      calculatePrices,
    }: {
      bagId: string | null;
      bagSku: string | null;
      bagKey: string | null;
      productsMap: ProductsMap;
      calculatePrices: CalculatePrices;
    }) => {
      if (loading || !bagId || !bagSku || !bagKey) return;
      const transformedProducts = transformToProductInput(productsMap);
      const reason = InventoryChangeReason.inbound_surprise_bag;
      try {
        const { data } = await updateSurpriseBagStockMutation({
          variables: {
            input: {
              products: transformedProducts,
              reason,
              sku: bagSku,
              transactionId: `${bagId}-${bagSku}-${reason}`,
            },
          },
        });
        if (!data?.updateSurpriseBagStock?.success) return;

        const { bagPrice, currentTotalPrice } = calculatePrices;
        sendSegmentTrackEvent("bagStateUpdated", {
          state: TGTGBagUpdateStates.BagPreparationFinished,
          bag_id: bagId!,
          bag_value_final: currentTotalPrice,
          bag_value_target: bagPrice,
          bag_type: bagKey,
          bag_value_limit: bagPrice + PRICE_LIMIT_THRESHOLD,
          bag_preparation_feature: TGTGBagPreparationFeatures.TgtgAssistant,
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : "Unknown error";
        datadogRum.addError("Error updateSurpriseBagStockMutation", {
          errorMessage,
          variables: {
            products: transformedProducts,
            reason,
            sku: bagSku,
            transactionId: `${bagId}-${bagSku}-${reason}`,
          },
        });
      }
    },
    [loading, sendSegmentTrackEvent, updateSurpriseBagStockMutation],
  );
  return { updateSurpriseBagStock, loading };
}
