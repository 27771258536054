import { Item } from "flows/Picking/models/item/types";

export enum DeliveryTag {
  HOME = "home",
  WORK = "work",
  OUTDOOR = "outdoor",
  OTHER = "other",
}

export interface Order {
  id: string;
  number: string | null;
  state: string | null;
  createdAt: string | null;
  isClickAndCollect: boolean;
  isNewCustomer: boolean;
  reseller:
    | "FLINK"
    | "UBER-EATS"
    | "UBER-EATS-CARREFOUR"
    | "WOLT"
    | "JUST-EAT"
    | "JUST-EAT-REWE"
    | "JUST-EAT-WEINFREUNDE"
    | "JUST-EAT-REWEREADY"
    | null;
  customerFirstName: string | null;
  customerLastName: string | null;
  externalProviderId: string | null;
  externalDeliveryProvider: "UBER-DIRECT" | "WOLT-DRIVE" | null;
  items?: Item[];
  handoverIdentifier: string | null;
  isInStore: boolean | null;
  ageVerification: "NONE" | "SIXTEEN" | "EIGHTEEN";
  weight: number;
  numberOfItems: number;
  deliveryTag: `${DeliveryTag}` | null;
}

export type OrderWithItems = Order & { items: Item[] };
