import { Box, BoxProps, Flex, FlexProps, Image } from "@chakra-ui/react";
import { useIntl } from "react-intl";

import { EppoFeatureFlags } from "core/types/flags";
import { useEppoFeatureFlagProvider } from "shared/hooks/useEppoFeatureFlag";
import { ProductFacing } from "ui/ProductFacing/ProductFacing";
import { StockPill } from "ui/StockPill/StockPill";
import { Tag, TagStateEnum } from "ui/Tag/Tag";
import {
  DetailL,
  DetailM,
  HeaderS,
  TitleS,
  TitleXS,
  TypographyProps,
} from "ui/Typography/Typography";
import { formatImage } from "utils/image";
import { isNotNullNorUndefined } from "utils/tsHelpers";

const IMAGE_SIZE_SMALL = 80;
const IMAGE_SIZE_SMEDIUM = 100;
const IMAGE_SIZE_MEDIUM = 120;
const IMAGE_SIZE_LARGE = 156;

const imageSizes = {
  s: IMAGE_SIZE_SMALL,
  sm: IMAGE_SIZE_SMEDIUM,
  m: IMAGE_SIZE_MEDIUM,
  l: IMAGE_SIZE_LARGE,
};

export type ProductInformationProps = {
  imageUrl?: string | null;
  stock?: number | string;
  name: string;
  sku?: string;
  countryOfOrigin?: string | null;
  variantSize?: "s" | "sm" | "m" | "l";
  stockStatus?: "validated" | "updated" | "unchanged";
  stockLabel?: string;
  showLeftSideBar?: boolean;
  showProductFacing?: boolean;
  isShelvedInHandlingUnits?: boolean;
  numberOfShelfFacings?: number;
  leftSideBarProps?: BoxProps;
  showSKU?: boolean;
  isCrossVisible?: boolean;
} & FlexProps;

const getStockVariantProps = (
  stockStatus: ProductInformationProps["stockStatus"],
): TypographyProps => {
  switch (stockStatus) {
    case "validated":
      return {
        bg: "veggie.100",
        color: "veggie.500",
      };
    case "updated":
      return {
        bg: "pinkFlink.100",
        color: "pinkFlink.500",
      };
    default:
      return {
        color: "grey.800",
      };
  }
};

export function ProductInformation({
  imageUrl,
  name,
  sku,
  countryOfOrigin,
  stock,
  stockStatus = "unchanged",
  variantSize = "m",
  showLeftSideBar = false,
  showProductFacing = false,
  isShelvedInHandlingUnits,
  numberOfShelfFacings,
  stockLabel,
  leftSideBarProps = {},
  showSKU = true,
  isCrossVisible = false,
  ...rest
}: ProductInformationProps) {
  const { isFeatureEnabled: isHideProductFacingEnabled } = useEppoFeatureFlagProvider(
    EppoFeatureFlags.HIDE_PRODUCT_FACINGS,
  );
  const shouldDisplayStock = isNotNullNorUndefined(stockStatus) && isNotNullNorUndefined(stock);
  const shouldDisplayStockLabel = isNotNullNorUndefined(stockLabel);

  const intl = useIntl();

  const displayedProductName = `${name}${
    isNotNullNorUndefined(countryOfOrigin) ? ` (${countryOfOrigin})` : ""
  }`;

  const isSmall = variantSize === "s";
  const currentImageSize = imageSizes[variantSize];
  const imageSize = `${currentImageSize}px`;
  const crossLineLength = Math.sqrt(2 * currentImageSize * currentImageSize) - 8;
  const crossLineStyleProps = {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: `${crossLineLength}px`,
    height: "5px",
    backgroundColor: "red.500",
  };
  const imageUrlFormatted = isNotNullNorUndefined(imageUrl)
    ? formatImage(imageUrl, 350, 350)
    : null;

  return (
    <Flex
      position="relative"
      w="100%"
      pr="s200"
      pl="s100"
      borderRadius="8px"
      data-testid="product-information"
      {...rest}
    >
      {showLeftSideBar && (
        <Box
          width="8px"
          h="100%"
          position="absolute"
          top={0}
          left={0}
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
          data-testid="product-info-left-sidebar"
          {...leftSideBarProps}
        />
      )}
      {isNotNullNorUndefined(imageUrlFormatted) && (
        <Image
          objectFit="cover"
          borderRadius="sm"
          h={imageSize}
          w={imageSize}
          src={imageUrlFormatted}
        />
      )}
      {isCrossVisible && (
        <Box
          position="absolute"
          top={0}
          left={0}
          h={imageSize}
          w={imageSize}
          bg="transparent"
          _before={{
            ...crossLineStyleProps,
            transform: "translate(-50%, -50%) rotate(45deg)",
          }}
          _after={{
            ...crossLineStyleProps,
            transform: "translate(-50%, -50%) rotate(-45deg)",
          }}
        />
      )}
      <Flex
        direction="column"
        flex={1}
        justifyContent="center"
        paddingX="s100"
        data-testid={`productCardSku-${sku}`}
      >
        {isSmall ? (
          <>
            <TitleXS color="grey.800" noOfLines={3}>
              {displayedProductName}
            </TitleXS>
            {showProductFacing && !isHideProductFacingEnabled ? (
              <ProductFacing
                isShelvedInHandlingUnits={!!isShelvedInHandlingUnits}
                numberOfFacings={numberOfShelfFacings ?? 0}
                bg="white"
                hideTitle
              />
            ) : (
              sku && <DetailM mt="s100" color="grey.600">{`SKU ${sku}`}</DetailM>
            )}
          </>
        ) : (
          <>
            <TitleS color="grey.800" noOfLines={3}>
              {displayedProductName}
            </TitleS>
            {showSKU ? (
              sku && <DetailL mt="s100" color="grey.600">{`SKU ${sku}`}</DetailL>
            ) : (
              <StockPill stock={stock} />
            )}
          </>
        )}
      </Flex>
      {showSKU && (
        <>
          {shouldDisplayStock && !shouldDisplayStockLabel && (
            <Flex direction="column" justifyContent="center" alignItems="center" gap="s50">
              <TitleS color="grey.800">
                {intl.formatMessage({ id: "components.task-product-card.stock-text" })}
              </TitleS>
              <HeaderS
                px="s100"
                borderRadius="sm"
                data-testid="product-information-stock"
                {...getStockVariantProps(stockStatus)}
              >
                {stock}
              </HeaderS>
            </Flex>
          )}
          {shouldDisplayStockLabel && (
            <Flex alignItems="center">
              <Tag
                label={stockLabel}
                state={stock === 0 ? TagStateEnum.Error : TagStateEnum.Success}
              />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
}
