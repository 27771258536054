import { ProductInput } from "__graphql__/types";

import { GENERATED_SKU_PREFIX, ProductsMap } from "../stores/useTGTGBagStore";

export const PRICE_LIMIT_THRESHOLD = 10;

export const isOverPriceLimit = (price: number | null, newTotalPrice: number): boolean => {
  return price !== null && newTotalPrice >= price + PRICE_LIMIT_THRESHOLD;
};

export const transformToProductInput = (data: ProductsMap): ProductInput[] => {
  return Object.values(data)
    .filter((product) => !product.productSku.startsWith(GENERATED_SKU_PREFIX))
    .map((product) => ({
      quantity: product.quantity,
      sku: product.productSku,
    }));
};
