import * as Types from "../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetDespatchAdvicesSummaryByRolliIdQueryVariables = Types.Exact<{
  input: Types.GetMultipleDesadvsRolliIdInput;
}>;

export type GetDespatchAdvicesSummaryByRolliIdQuery = {
  __typename?: "Query";
  getMultipleDesadvsRolliID: {
    __typename?: "GetMultipleDesadvsRolliIDResponse";
    despatchAdvices: Array<{
      __typename?: "MultipleDesadv";
      id: string;
      hubSlug?: string | null;
      expectedDeliveryDate?: any | null;
    }>;
  };
};

export type GetDespatchAdviceDeliveriesForHubQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetDespatchAdviceDeliveriesForHubQuery = {
  __typename?: "Query";
  getDespatchAdviceDeliveriesForHub: {
    __typename?: "GetDespatchAdviceDeliveriesForHubResponse";
    despatchAdviceDeliveriesForHub: Array<{
      __typename?: "DespatchAdviceDeliveriesForHub";
      id: string;
      estimatedDeliveryDate?: any | null;
      hubSlug: string;
      ssccs: Array<string>;
    }>;
  };
};

export const GetDespatchAdvicesSummaryByRolliIdDocument = gql`
  query getDespatchAdvicesSummaryByRolliID($input: GetMultipleDesadvsRolliIDInput!) {
    getMultipleDesadvsRolliID(GetMultipleDesadvsRolliIDInput: $input) {
      despatchAdvices {
        id
        hubSlug
        expectedDeliveryDate
      }
    }
  }
`;

/**
 * __useGetDespatchAdvicesSummaryByRolliIdQuery__
 *
 * To run a query within a React component, call `useGetDespatchAdvicesSummaryByRolliIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDespatchAdvicesSummaryByRolliIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDespatchAdvicesSummaryByRolliIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDespatchAdvicesSummaryByRolliIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDespatchAdvicesSummaryByRolliIdQuery,
    GetDespatchAdvicesSummaryByRolliIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDespatchAdvicesSummaryByRolliIdQuery,
    GetDespatchAdvicesSummaryByRolliIdQueryVariables
  >(GetDespatchAdvicesSummaryByRolliIdDocument, options);
}
export function useGetDespatchAdvicesSummaryByRolliIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDespatchAdvicesSummaryByRolliIdQuery,
    GetDespatchAdvicesSummaryByRolliIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDespatchAdvicesSummaryByRolliIdQuery,
    GetDespatchAdvicesSummaryByRolliIdQueryVariables
  >(GetDespatchAdvicesSummaryByRolliIdDocument, options);
}
export type GetDespatchAdvicesSummaryByRolliIdQueryHookResult = ReturnType<
  typeof useGetDespatchAdvicesSummaryByRolliIdQuery
>;
export type GetDespatchAdvicesSummaryByRolliIdLazyQueryHookResult = ReturnType<
  typeof useGetDespatchAdvicesSummaryByRolliIdLazyQuery
>;
export type GetDespatchAdvicesSummaryByRolliIdQueryResult = Apollo.QueryResult<
  GetDespatchAdvicesSummaryByRolliIdQuery,
  GetDespatchAdvicesSummaryByRolliIdQueryVariables
>;
export const GetDespatchAdviceDeliveriesForHubDocument = gql`
  query getDespatchAdviceDeliveriesForHub {
    getDespatchAdviceDeliveriesForHub {
      despatchAdviceDeliveriesForHub {
        id
        estimatedDeliveryDate
        hubSlug
        ssccs
      }
    }
  }
`;

/**
 * __useGetDespatchAdviceDeliveriesForHubQuery__
 *
 * To run a query within a React component, call `useGetDespatchAdviceDeliveriesForHubQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDespatchAdviceDeliveriesForHubQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDespatchAdviceDeliveriesForHubQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDespatchAdviceDeliveriesForHubQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >(GetDespatchAdviceDeliveriesForHubDocument, options);
}
export function useGetDespatchAdviceDeliveriesForHubLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDespatchAdviceDeliveriesForHubQuery,
    GetDespatchAdviceDeliveriesForHubQueryVariables
  >(GetDespatchAdviceDeliveriesForHubDocument, options);
}
export type GetDespatchAdviceDeliveriesForHubQueryHookResult = ReturnType<
  typeof useGetDespatchAdviceDeliveriesForHubQuery
>;
export type GetDespatchAdviceDeliveriesForHubLazyQueryHookResult = ReturnType<
  typeof useGetDespatchAdviceDeliveriesForHubLazyQuery
>;
export type GetDespatchAdviceDeliveriesForHubQueryResult = Apollo.QueryResult<
  GetDespatchAdviceDeliveriesForHubQuery,
  GetDespatchAdviceDeliveriesForHubQueryVariables
>;
