import { useCallback } from "react";

import { usePickingUIStore } from "flows/Picking/hooks/usePickingUIStore";
import { useAnalytics } from "shared/hooks/useAnalytics";

import { PickingEvents, PickingMachineContext } from "./types";
import { getItemWithPickingState } from "./utils";

export function usePickingActionsImplems() {
  const { sendSegmentTrackEvent } = useAnalytics();
  const setPickingUIState = usePickingUIStore((state) => state.setPickingUIState);
  const trackOrderPicked = (ctx: PickingMachineContext) => {
    sendSegmentTrackEvent("orderStateUpdated", {
      state: "picked",
      origin: ctx.origin,
      order_number: ctx.order?.number,
      order_id: ctx.order?.id,
    });
  };

  const sendSegmentOrderProgressed = (ctx: PickingMachineContext, event: PickingEvents) => {
    let action;
    let pickingMethod;
    let quantity;

    // Only event with products and orders are concerned.
    if (
      (event.type !== "INCREMENT_ITEM_QUANTITY" &&
        event.type !== "SKIP_ITEM" &&
        event.type !== "RESET_ITEM") ||
      !ctx.order?.id ||
      !ctx.order?.number
    )
      return;

    const product = getItemWithPickingState(
      event.itemId,
      ctx,
      event.type === "SKIP_ITEM" ? event.isSkippedItem : false,
    );
    if (!product) {
      return;
    }

    // Define attributes for different possible events.
    switch (event.type) {
      case "INCREMENT_ITEM_QUANTITY":
        action = "item_picked";
        quantity = 1;
        if (event.reportedBarcodeIssue) {
          pickingMethod = "reported";
        } else if (event.pickedByScan) {
          pickingMethod = "scanned";
          sendSegmentTrackEvent("orderProgressed", {
            action,
            order_id: ctx.order.id,
            order_number: ctx.order.number,
            ean: product.item.eans,
            product_sku: product.item.sku,
            quantity,
            method: pickingMethod,
            reason: null,
          });
        } else {
          pickingMethod = "clicked";
        }
        break;
      case "SKIP_ITEM":
        action = event.isSkippedItem ? "item_refunded" : "item_skipped";
        quantity = product.item.quantity - product.pickedQuantity;
        break;
      case "RESET_ITEM":
        action = "item_reset";
        quantity = product.pickedQuantity;
        break;
      default:
        return;
    }

    if (pickingMethod !== "scanned") {
      sendSegmentTrackEvent("orderProgressed", {
        action,
        order_id: ctx.order.id,
        order_number: ctx.order.number,
        ean: product.item.eans,
        product_sku: product.item.sku,
        quantity: quantity > 0 ? quantity : 0,
        method: pickingMethod,
        reason: null,
      });
    }
  };

  const sendSegmentContainerStatus = (ctx: PickingMachineContext) => {
    const currentOrder = ctx.order;

    if (ctx.containersIds.length) {
      sendSegmentTrackEvent("containerAssigned", {
        type: "Container",
        method: ctx.inputMethod === "MANUAL" ? "manual" : "scanned",
        container_ids: ctx.containersIds,
        order_number: currentOrder?.number,
        order_id: currentOrder?.id,
      });
      return;
    }
    sendSegmentTrackEvent("containerAssignmentSkipped", {
      type: "Container",
      order_number: currentOrder?.number,
      order_id: currentOrder?.id,
    });
  };

  const sendSegmentShelfStatus = (ctx: PickingMachineContext) => {
    const currentOrder = ctx.order;

    if (ctx.shelvesIds.length) {
      sendSegmentTrackEvent("containerAssigned", {
        type: "Shelf",
        container_ids: ctx.shelvesIds,
        method: ctx.inputMethod === "MANUAL" ? "manual" : "scanned",
        order_number: currentOrder?.number,
        order_id: currentOrder?.id,
      });
      return;
    }
    sendSegmentTrackEvent("containerAssignmentSkipped", {
      type: "Shelf",
      order_number: currentOrder?.number,
      order_id: currentOrder?.id,
    });
  };

  const trackOrderPacked = (ctx: PickingMachineContext) => {
    sendSegmentTrackEvent("orderStateUpdated", {
      state: "packed",
      origin: ctx.origin,
      order_number: ctx.order?.number,
      order_id: ctx.order?.id,
    });
  };

  const trackOrderPickingFinished = (ctx: PickingMachineContext) => {
    sendSegmentTrackEvent("orderStateUpdated", {
      state: "picking_finished",
      origin: ctx.origin,
      order_number: ctx.order?.number,
      order_id: ctx.order?.id,
    });
  };

  const showOrderCompletionWarning = useCallback(() => {
    setPickingUIState({ shouldShowOrderCompletionWarning: true });
  }, [setPickingUIState]);

  const hideOrderCompletionWarning = useCallback(() => {
    setPickingUIState({ shouldShowOrderCompletionWarning: false });
  }, [setPickingUIState]);

  return {
    trackOrderPicked,
    trackOrderPacked,
    sendSegmentOrderProgressed,
    sendSegmentContainerStatus,
    sendSegmentShelfStatus,
    trackOrderPickingFinished,
    showOrderCompletionWarning,
    hideOrderCompletionWarning,
  };
}
