import { useRef, createRef, useLayoutEffect, RefObject } from "react";

import { isNotNullNorUndefined, isNullOrUndefined } from "utils/tsHelpers";

export function useScrollToActiveItem({
  activeItemId,
  listItemIds,
  isScrollToActiveItem,
  setInboundUIState,
}: {
  activeItemId: string | null;
  listItemIds: string[];
  isScrollToActiveItem: boolean;
  setInboundUIState: (state: any) => void;
}) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<RefObject<HTMLDivElement>[]>(
    listItemIds.map(() => createRef<HTMLDivElement>()),
  );

  useLayoutEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (isNullOrUndefined(scrollContainer)) {
      setInboundUIState({ isScrollToActiveItem: false });
      return;
    }
    if (!activeItemId) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: "smooth",
      });
      setInboundUIState({ isScrollToActiveItem: false });
      return;
    }
    const activeItemIndex = listItemIds.indexOf(activeItemId);
    const activeItemRef = itemsRef.current[activeItemIndex];
    if (activeItemIndex >= 0 && isNotNullNorUndefined(activeItemRef?.current)) {
      const { offsetTop } = activeItemRef.current;
      scrollContainer.scrollTo({
        top: offsetTop - 12,
        behavior: "smooth",
      });
      setInboundUIState({ isScrollToActiveItem: false });
    }
  }, [isScrollToActiveItem, activeItemId, listItemIds, setInboundUIState]);

  return {
    scrollContainerRef,
    itemsRef,
  };
}
