import { useCallback, useState } from "react";

import { Flex } from "@chakra-ui/react";
import { useNavigate } from "react-router";

import { RestockingItemStatus } from "__graphql__/types";
import { routes } from "config/routes";
import { EndDroppingProcessButton } from "flows/Inbound/components/EndDroppingProcessButton";
import { usePrivateRestockingList } from "flows/Inventory/flows/RestockingList/services/restockingService/service";
import { ScanPlaceholder } from "flows/Inventory/shared/components/ScanPlaceholder";
import { useAnalytics } from "shared/hooks/useAnalytics";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";
import { Spinner } from "ui/Spinner/Spinner";

import { RestockingListItem } from "./RestockingListItem";

export function RestockingListPreparationList({
  isLoading,
  scrollContainer,
}: {
  isLoading: boolean;
  scrollContainer: React.RefObject<HTMLDivElement | null>;
}) {
  const navigate = useNavigate();
  const { sendSegmentTrackEvent } = useAnalytics();
  const { translatedRestockingItems, removeRestockingItem, privateRestockingList } =
    usePrivateRestockingList();

  const [itemBeingRemoved, setItemBeingRemoved] = useState<string | null>(null);

  const handleRemoveRestockingItem = useCallback(
    async (sku: string) => {
      try {
        setItemBeingRemoved(sku);
        await removeRestockingItem(sku);
      } finally {
        setItemBeingRemoved(null);
      }
    },
    [removeRestockingItem],
  );

  const startRestockingList = () => {
    sendSegmentTrackEvent("restockingStateUpdated", {
      restocking_list_id: privateRestockingList?.id ?? "",
      state: "restocking_list_started",
    });
    navigate(routes.inventory.restockingList.dropOff);
  };

  if (isLoading) {
    return (
      <Flex h="100%" alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }
  if (translatedRestockingItems.length === 0) {
    return (
      <ScanPlaceholder
        title="pages.inventory.restocking-list.instructions.title"
        subtitle="pages.inventory.restocking-list.instructions.subtitle"
        justifyContent="center"
        flexGrow="1"
        px="s400"
      />
    );
  }
  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      pb="72px"
      gap="s200"
    >
      {translatedRestockingItems.map((restockingItem) => (
        <RestockingListItem
          key={restockingItem.sku}
          restockingItem={restockingItem}
          onClickRemove={handleRemoveRestockingItem}
          isShowingSwipeButtons={restockingItem.status !== RestockingItemStatus.done}
          isShowingCompleteCardsAsBlocked
          scrollContainer={scrollContainer}
          isDisabled={itemBeingRemoved === restockingItem.sku}
        />
      ))}
      <EndDroppingProcessButton
        shouldShowButton
        onClickButton={startRestockingList}
        labelMessageId="flows.inventory.restocking-list.pages.list-preparation.start-restocking"
        isLoading={false}
        bottom={BOTTOM_TAB_BAR_HEIGHT_NUMBER}
      />
    </Flex>
  );
}
