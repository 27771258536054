import { useApolloClient } from "@apollo/client";

import { useCustomToast } from "shared/hooks/useCustomToast";
import { useHubSlug } from "shared/hooks/useHubSlug";
import { BOTTOM_TAB_BAR_HEIGHT_NUMBER } from "ui/BottomTabBarContainer";

import {
  GetDespatchAdviceDeliveriesForHubDocument,
  GetDespatchAdviceDeliveriesForHubQuery,
  GetDespatchAdviceDeliveriesForHubQueryVariables,
  GetDespatchAdvicesSummaryByRolliIdQuery,
  useGetDespatchAdvicesSummaryByRolliIdLazyQuery,
} from "../../queries/despatchAdvice/despatchAdvice.generated";
import { ModalNames, Rolli, RolliStatus, useCheckinUIStore } from "../../stores/useCheckInUIStore";

const WAREHOUSE_NUMBERS = ["500", "501"];

export function useDeliveryCheckinService() {
  const client = useApolloClient();
  const hubSlug = useHubSlug();
  const { showToastUI } = useCustomToast();

  const { scannedRollies, setCheckinUIState, setShowModal, isScanningCrates } = useCheckinUIStore(
    (state) => ({
      scannedRollies: state.scannedRollies,
      setShowModal: state.setShowModal,
      isScanningCrates: state.isScanningCrates,
      setCheckinUIState: state.setCheckinUIState,
    }),
  );

  const extractWarehouseNumber = (sscc: string) => sscc.slice(-10, -7); // We are supposing that Warehouse numbers always are in the middle of 8th digit to 11th digit and we alywas will slice from the right

  const isCrateFromWarehouse = (sscc: string) =>
    WAREHOUSE_NUMBERS.includes(extractWarehouseNumber(sscc));

  const [fetchDesadvsSummary] = useGetDespatchAdvicesSummaryByRolliIdLazyQuery({
    fetchPolicy: "network-only",
    onError: () => {
      return null;
    },
  });

  const fetchExpectedDeliveriesForToday =
    async (): Promise<GetDespatchAdviceDeliveriesForHubQuery | null> => {
      try {
        const result = await client.query<
          GetDespatchAdviceDeliveriesForHubQuery,
          GetDespatchAdviceDeliveriesForHubQueryVariables
        >({
          query: GetDespatchAdviceDeliveriesForHubDocument,
          fetchPolicy: "network-only",
        });
        return result.data;
      } catch {
        return null;
      }
    };

  const determineRolliesState = async (
    scannedRolliId: string,
    desadvsSummary?: GetDespatchAdvicesSummaryByRolliIdQuery,
  ): Promise<Rolli[]> => {
    const UNKNOWN_ROLLI: Rolli = {
      desadv_id: null,
      sscc: scannedRolliId,
      isRolliNotMatch: true,
      status: null,
      expected_hub: null,
    };

    const despatchAdvices = desadvsSummary?.getMultipleDesadvsRolliID?.despatchAdvices || [];
    if (despatchAdvices.length > 0) {
      const matchingDesadvs = despatchAdvices.filter((desadv) => desadv.hubSlug === hubSlug);
      if (matchingDesadvs.length > 0) {
        return matchingDesadvs.map((desadv) => ({
          ...UNKNOWN_ROLLI,
          desadv_id: desadv.id,
          isRolliNotMatch: false,
          status: RolliStatus.ACCEPTED,
          expected_hub: desadv.hubSlug || null,
        }));
      }
      setShowModal(ModalNames.POTENTIAL_INCORRECT_ROLLI, true);
      const firstDesadv = despatchAdvices[0];
      return [
        {
          ...UNKNOWN_ROLLI,
          desadv_id: firstDesadv.id,
          expected_hub: firstDesadv.hubSlug || null,
        },
      ];
    }

    if (isScanningCrates) {
      setShowModal(ModalNames.WRONG_ROLLI, true);
      return [UNKNOWN_ROLLI];
    }

    const expectedDeliveriesData = await fetchExpectedDeliveriesForToday();

    if (
      expectedDeliveriesData === null ||
      !expectedDeliveriesData.getDespatchAdviceDeliveriesForHub.despatchAdviceDeliveriesForHub
        .length
    ) {
      setShowModal(ModalNames.DELIVERY_DATA_UNAVAILABLE, true);
      return [UNKNOWN_ROLLI];
    }

    if (!isCrateFromWarehouse(scannedRolliId)) {
      setShowModal(ModalNames.WRONG_ROLLI, true);
      return [UNKNOWN_ROLLI];
    }

    setShowModal(ModalNames.ROLLI_CONTAINS_CRATES, true);
    setCheckinUIState({ isScanningCrates: true });
    return [];
  };

  const checkScannedRolli = async (scannedRolliId: string) => {
    if (scannedRollies?.some((item) => item.sscc === scannedRolliId)) {
      showToastUI({
        title: "flows.activities.services.scanned-already-rolli",
        variant: "warning",
        containerStyle: {
          background: "#33448D",
          borderRadius: "8px",
          color: "white",
          bottom: BOTTOM_TAB_BAR_HEIGHT_NUMBER,
          position: "absolute",
        },
      });
      setCheckinUIState({ highlightedRolliId: scannedRolliId });
      setTimeout(() => {
        setCheckinUIState({ highlightedRolliId: null });
      }, 3000);

      return;
    }

    const { data: desadvsSummary } = await fetchDesadvsSummary({
      variables: { input: { rolliID: scannedRolliId } },
    });
    const rollies = await determineRolliesState(scannedRolliId, desadvsSummary);
    const updatedRollies = [...rollies, ...(scannedRollies || [])];
    setCheckinUIState({ scannedRollies: updatedRollies });
  };

  return {
    checkScannedRolli,
  };
}
