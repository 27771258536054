import { useIntl } from "react-intl";

import { YesNoModal, YesNoModalProps } from "shared/components/YesNoModal";

export type OverGoalWarningModalProps = Pick<YesNoModalProps, "onClickYes" | "onClickNo">;

export function OverGoalWarningModal({ onClickYes, onClickNo }: OverGoalWarningModalProps) {
  const intl = useIntl();
  return (
    <YesNoModal
      isOpen
      onClickYes={onClickYes}
      onClickNo={onClickNo}
      title={intl.formatMessage({
        id: "components.over-goal-warning-modal.title",
      })}
      body={intl.formatMessage({
        id: "components.over-goal-warning-modal.body",
      })}
      yesLabel={intl.formatMessage({
        id: "generic.actions.continue-anyway",
      })}
      noLabel={intl.formatMessage({
        id: "generic.actions.back",
      })}
      isGrey
    />
  );
}
