import { Flex, Image } from "@chakra-ui/react";
import shallow from "zustand/shallow";

import { useAnalytics } from "shared/hooks/useAnalytics";
import { BodyS, DetailL, HeaderS } from "ui/Typography/Typography";
import { isNotNullNorUndefined } from "utils/tsHelpers";

import { useTGTGBagStore } from "../stores/useTGTGBagStore";
import { ProductCardCounter } from "./ProductCardCounter";

export function ProductInfoCard({ productSku }: { productSku: string }) {
  const { sendSegmentTrackEvent } = useAnalytics();
  const { productsMap, increaseQuantity, decreaseQuantity } = useTGTGBagStore(
    (state) => ({
      productsMap: state.productsMap,
      increaseQuantity: state.increaseQuantity,
      decreaseQuantity: state.decreaseQuantity,
    }),
    shallow,
  );
  const product = productsMap[productSku];
  const currentProductTotalPrice =
    Math.round((product.productPrice ?? 0) * product.quantity * 100) / 100;
  const formattedPrice = currentProductTotalPrice.toFixed(2);
  const hasImg = isNotNullNorUndefined(product.productImageUrl);
  return (
    <Flex
      w="100%"
      h="96px"
      px="s100"
      gap="s200"
      alignItems="center"
      justify="flex-start"
      bg="grey.150"
    >
      <Flex
        bg={hasImg ? "grey.100" : "grey.200"}
        borderRadius="8px"
        h="80px"
        w="80px"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        {hasImg ? (
          <Image
            maxHeight="100%"
            h="80px"
            w="80px"
            objectFit="contain"
            src={product.productImageUrl!}
          />
        ) : (
          <BodyS>no image</BodyS>
        )}
      </Flex>
      <Flex direction="column" justify="space-between" flex={1} h="80px">
        <DetailL>{product.productName}</DetailL>
        <ProductCardCounter
          total={product.quantity}
          onIncrementItemQuantityClick={() => increaseQuantity(productSku, sendSegmentTrackEvent)}
          onDecrementItemQuantityClick={() => decreaseQuantity(productSku, sendSegmentTrackEvent)}
        />
      </Flex>
      <Flex w="80px" justify="center" align="center">
        <HeaderS>{formattedPrice}€</HeaderS>
      </Flex>
    </Flex>
  );
}
