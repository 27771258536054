import { Box, Flex } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import { Button } from "ui/Button/Button";
import { BagIcon } from "ui/Icons/Icons";
import { TitleM } from "ui/Typography/Typography";

import { isOverPriceLimit, PRICE_LIMIT_THRESHOLD } from "../utils/helpers";

type EndPreparationButtonProps = {
  bagOfPrice: number;
  totalPrice: number;
  onClickButton: () => void;
};

export function EndPreparationButton({
  bagOfPrice,
  totalPrice,
  onClickButton,
}: EndPreparationButtonProps) {
  const priceGap = totalPrice < bagOfPrice ? Math.round((bagOfPrice - totalPrice) * 100) / 100 : 0;
  const overMaximum = isOverPriceLimit(bagOfPrice, totalPrice);
  const isDisabled = priceGap > 0 || overMaximum;
  if (isDisabled) {
    const overMaximumAmount = overMaximum
      ? Math.round((totalPrice - (bagOfPrice + PRICE_LIMIT_THRESHOLD)) * 100) / 100
      : 0;
    return (
      <Box w="100%" h="72px" px="s100" py="s150" pos="fixed" bottom={0} zIndex={1}>
        <Button width="full" flinkVariant="primary" size="lg" position="relative" disabled>
          <BagIcon boxSize="24px" position="absolute" left="16px" />
          <FormattedMessage
            id={
              overMaximum
                ? "pages.tgtg-bag-selection-page.end-button.over-maximum"
                : "pages.tgtg-bag-selection-page.end-button.progress"
            }
            values={{ price: overMaximum ? overMaximumAmount : priceGap }}
          />
        </Button>
      </Box>
    );
  }

  return (
    <Box w="100%" h="72px" px="s100" py="s150" pos="fixed" bottom={0} zIndex={1}>
      <Button
        width="full"
        flinkVariant="primary"
        size="lg"
        onClick={onClickButton}
        display="flex"
        justifyContent="space-between"
        px="s200"
      >
        <Flex align="center" gap="s200">
          <BagIcon boxSize="24px" />
          <FormattedMessage id="pages.tgtg-bag-selection-page.end-button.complete-preparation" />
        </Flex>
        <TitleM color="white">{totalPrice} €</TitleM>
      </Button>
    </Box>
  );
}
