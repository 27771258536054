import { FormattedMessage, useIntl } from "react-intl";

import { CtaModal } from "ui/CtaModal";
import { BodyM } from "ui/Typography/Typography";

export function OverMaximumWarningModal({ onClose }: { onClose: () => void }) {
  const intl = useIntl();
  return (
    <CtaModal
      isOpen
      onClose={() => {}}
      hideCloseButton
      onClickCta={onClose}
      title={intl.formatMessage({
        id: "components.over-maximum-warning-modal.title",
      })}
      ctaLabel={intl.formatMessage({ id: "generic.actions.back" })}
      body={
        <BodyM textAlign="center">
          <FormattedMessage id="components.over-maximum-warning-modal.body" />
        </BodyM>
      }
      flinkVariant="secondary"
    />
  );
}
