import { useCallback, useState } from "react";

import { ApolloError } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import shallow from "zustand/shallow";

import { InventoryChangeReason } from "__graphql__/types";
import { useInboundEventTracker } from "flows/Inbound/hooks/useInboundEventTracker/useInboundEventTracker";
import { useInboundStore } from "flows/Inbound/stores/inboundStore/useInboundStore";
import {
  getMatchedtDesadvId,
  selectDespatchAdviceItemBySku,
} from "flows/Inbound/stores/inboundStore/utils";
import { useCustomToast } from "shared/hooks/useCustomToast";
import { useUpdateProductStockByDeltaAndMultipleReasonsMutation } from "shared/queries/inventoryEntry/inventoryEntry.generated";

import {
  useDeleteInboundingListProductsMutation,
  useEditProductsInInboundingListMutation,
} from "../queries/collaborativeInbound/collaborativeInbound.generated";
import { StockUpdatePlan } from "./inboundStore/types";

const PRODUCT_NOT_ASSIGNED_ERROR = "product-not-assigned-error";
const UNABLE_TO_CREATE_SKU_HUB_ASSOCIATION_ERROR = "unable-create-sku-hub-association-error";
const UPDATE_INBOUND_UNIT_PRODUCT_STOCK_ERROR = "update_inbound_unit_product_stock_error_toast";
const OUTBOUND_SUCCESS = "outbound_success_toast";

export function useUpdateStockForOutboundReasons() {
  const { showToastUI } = useCustomToast();
  const { sendOutboundUpdate } = useInboundEventTracker();

  const [isStockUpdating, setIsStockUpdating] = useState(false);
  const {
    deliverySSCC,
    sharedListId,
    despatchAdviceItems,
    inboundUnitsMap,
    inboundUnitsStockUpdates,
    inboundUnitsDisplayStates,
    updateStockForOutboundReasons,
    removeInboundUnitFromPreDroppingList,
  } = useInboundStore(
    (state) => ({
      deliverySSCC: state.deliverySSCC,
      sharedListId: state.sharedListId,
      despatchAdviceItems: state.despatchAdviceItems,
      inboundUnitsMap: state.inboundUnitsMap,
      inboundUnitsStockUpdates: state.inboundUnitsStockUpdates,
      inboundUnitsDisplayStates: state.inboundUnitsDisplayStates,
      updateStockForOutboundReasons: state.updateStockForOutboundReasons,
      removeInboundUnitFromPreDroppingList: state.removeInboundUnitFromPreDroppingList,
    }),
    shallow,
  );
  const [updateProductStockMutation] = useUpdateProductStockByDeltaAndMultipleReasonsMutation();
  const [editPreDropQuantity] = useEditProductsInInboundingListMutation();
  const [deleteInboundingListProducts] = useDeleteInboundingListProductsMutation();

  const handleErrorToast = useCallback(
    (errorCode: string | undefined) => {
      if (errorCode === "INVENTORY_ENTRY_NOT_FOUND") {
        showToastUI({
          id: PRODUCT_NOT_ASSIGNED_ERROR,
          title: "hooks.use-inbound-action-implems.product-not-assigned-error",
        });
      } else if (errorCode === "INVENTORY_ENTRY_UNABLE_CREATE_SKU_HUB_ASSOCIATION") {
        showToastUI({
          id: UNABLE_TO_CREATE_SKU_HUB_ASSOCIATION_ERROR,
          title: "hooks.use-inbound-action-implems.association-creation-failed-error",
        });
      } else {
        showToastUI({
          id: UPDATE_INBOUND_UNIT_PRODUCT_STOCK_ERROR,
          title: "hooks.use-update-inbound-unit-product-stock.error-title",
          description: "hooks.use-update-inbound-unit-product-stock.error-description",
        });
      }
    },
    [showToastUI],
  );

  const getAdditionalMetaData = useCallback(
    (sku: string, ean: string, inboundingMethod?: string) => {
      const despatchAdviceItem = selectDespatchAdviceItemBySku(sku, despatchAdviceItems);
      const desadv = getMatchedtDesadvId(sku, despatchAdviceItems);
      const isMethodScan = inboundingMethod === "scan";
      const scannedEAN = isMethodScan ? ean : null;
      if (despatchAdviceItem) {
        return {
          desadv,
          expectedQuantity: despatchAdviceItem.totalQuantity.toString(),
          inboundingMethod,
          inboundingUnit: despatchAdviceItem.unitType,
          scannedEAN,
        };
      }
      return isMethodScan ? { inboundingMethod, scannedEAN } : {};
    },
    [despatchAdviceItems],
  );

  const updateStock = useCallback(
    async (sku: string, quantitiesByReason: StockUpdatePlan[]) => {
      setIsStockUpdating(true);
      try {
        const stockUpdateData = inboundUnitsStockUpdates[sku];
        const displayState = inboundUnitsDisplayStates[sku];

        if (!stockUpdateData) throw new Error("Missing stock update plan");
        if (!displayState) throw new Error("Missing display state");

        const currentInboundStock = stockUpdateData.stockUpdatePlan.find(
          (update) => update.reason === InventoryChangeReason.inbound_goods_received,
        );

        if (!currentInboundStock) throw new Error("No inbound goods received entry found");

        const inboundQuantityDelta = currentInboundStock.quantityDelta;
        if (inboundQuantityDelta === 0) throw new Error("Stock updates must contain inbound goods");

        const filteredQuantitiesByReason = quantitiesByReason.filter(
          (plan) => plan.quantityDelta !== 0,
        );

        const totalOutbound = filteredQuantitiesByReason.reduce(
          (sum, { quantityDelta }) => sum + Math.abs(quantityDelta),
          0,
        );
        const newInboundQuantityDelta = inboundQuantityDelta - totalOutbound;
        const updatedStockUpdatePlan: StockUpdatePlan[] = [
          {
            reason: InventoryChangeReason.inbound_goods_received,
            quantityDelta: totalOutbound,
          },
          ...filteredQuantitiesByReason,
        ];

        const { ean = "", inboundingMethod, shelf } = inboundUnitsMap[sku];
        const metadata = getAdditionalMetaData(sku, ean, inboundingMethod);

        if (!sharedListId) throw new Error("No sharedListId found");

        await updateProductStockMutation({
          variables: {
            updateProductStockByDeltaAndMultipleReasonsInput: {
              sku,
              stockUpdates: updatedStockUpdatePlan.map(({ quantityDelta, reason }) => ({
                quantityDelta,
                reason,
                transactionId: `${sharedListId}-${sku}-${reason}`,
                sscc: deliverySSCC ?? null,
                ...metadata,
              })),
            },
          },
        });
        if (newInboundQuantityDelta === 0) {
          await deleteInboundingListProducts({
            variables: { input: { listId: sharedListId, skus: [sku] } },
          });
          removeInboundUnitFromPreDroppingList(sku);
        } else {
          await editPreDropQuantity({
            variables: {
              input: {
                listId: sharedListId,
                products: [{ sku, quantity: newInboundQuantityDelta, handlingUnitSize: 1 }],
              },
            },
          });
          updateStockForOutboundReasons(sku, newInboundQuantityDelta);
        }
        sendOutboundUpdate({ sku, updatedStockUpdatePlan, shelf });
        showToastUI({
          status: "success",
          id: OUTBOUND_SUCCESS,
          title: "toast.outbound.success.title",
          description: "toast.outbound.success.description",
        });
      } catch (error) {
        const errorCode =
          error instanceof ApolloError
            ? (error.graphQLErrors?.[0]?.extensions?.code as string | undefined)
            : "Unknown error";
        const errorMessage = error instanceof Error ? error.message : errorCode;
        datadogRum.addError("Error while updating inbound unit product stock by reasons", {
          errorMessage,
        });
        handleErrorToast(errorCode);
      } finally {
        setIsStockUpdating(false);
      }
    },
    [
      deleteInboundingListProducts,
      deliverySSCC,
      editPreDropQuantity,
      getAdditionalMetaData,
      handleErrorToast,
      inboundUnitsDisplayStates,
      inboundUnitsMap,
      inboundUnitsStockUpdates,
      removeInboundUnitFromPreDroppingList,
      sendOutboundUpdate,
      sharedListId,
      showToastUI,
      updateProductStockMutation,
      updateStockForOutboundReasons,
    ],
  );

  return { updateStock, isStockUpdating };
}
