import * as Types from "../../../../../__graphql__/types.js";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type UpdateSurpriseBagStockMutationVariables = Types.Exact<{
  input: Types.UpdateSurpriseBagStockInput;
}>;

export type UpdateSurpriseBagStockMutation = {
  __typename?: "Mutation";
  updateSurpriseBagStock: { __typename?: "UpdateSurpriseBagStockResponse"; success: boolean };
};

export const UpdateSurpriseBagStockDocument = gql`
  mutation UpdateSurpriseBagStock($input: UpdateSurpriseBagStockInput!) {
    updateSurpriseBagStock(updateSurpriseBagStockInput: $input) {
      success
    }
  }
`;
export type UpdateSurpriseBagStockMutationFn = Apollo.MutationFunction<
  UpdateSurpriseBagStockMutation,
  UpdateSurpriseBagStockMutationVariables
>;

/**
 * __useUpdateSurpriseBagStockMutation__
 *
 * To run a mutation, you first call `useUpdateSurpriseBagStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurpriseBagStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurpriseBagStockMutation, { data, loading, error }] = useUpdateSurpriseBagStockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSurpriseBagStockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSurpriseBagStockMutation,
    UpdateSurpriseBagStockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSurpriseBagStockMutation,
    UpdateSurpriseBagStockMutationVariables
  >(UpdateSurpriseBagStockDocument, options);
}
export type UpdateSurpriseBagStockMutationHookResult = ReturnType<
  typeof useUpdateSurpriseBagStockMutation
>;
export type UpdateSurpriseBagStockMutationResult =
  Apollo.MutationResult<UpdateSurpriseBagStockMutation>;
export type UpdateSurpriseBagStockMutationOptions = Apollo.BaseMutationOptions<
  UpdateSurpriseBagStockMutation,
  UpdateSurpriseBagStockMutationVariables
>;
