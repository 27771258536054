import { useEffect, useRef, useState } from "react";

import { AddIcon } from "@chakra-ui/icons";
import { chakra, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { useSelector } from "@xstate/react";
import { FormattedMessage, useIntl } from "react-intl";

import { EndPickingButton } from "flows/Picking/components/EndPickingButton";
import { OrderCompletionWarningModal } from "flows/Picking/components/OrderCompletionWarningModal";
import { ShelfAssignmentSkipConfirmationModal } from "flows/Picking/components/ShelfAssignmentSkipConfirmationModal";
import { SkipScanningButton } from "flows/Picking/components/SkipScanningButton";
import { usePickingService } from "flows/Picking/hooks/usePickingService";
import { usePickingUIStore } from "flows/Picking/hooks/usePickingUIStore";
import { Page } from "shared/components/Page";
import { ScannableInput, ScannableInputHandle } from "shared/components/ScannableInput";
import { IntlMessageId } from "shared/types/lang";
import { RemovableListItem } from "ui/RemovableListItem/RemovableListItem";
import { BodyM, HeaderS } from "ui/Typography/Typography";

const isValidShelfId = (shelfId: string): boolean => {
  return /^[0-9]{1,3}$/.test(shelfId);
};

export function AssignShelvesPage() {
  const intl = useIntl();

  const pickingService = usePickingService();

  const { send } = pickingService;

  const [errorTextId, setErrorTextId] = useState<IntlMessageId | undefined>(undefined);
  const [typedValue, setTypedValue] = useState<string>();

  const refInput = useRef<ScannableInputHandle>();
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);

  const shelvesIds = useSelector(pickingService, (state) => state.context.shelvesIds);
  const endPickingLoading = useSelector(pickingService, (state) =>
    state.matches("endPicking.loading"),
  );
  const endPickingError = useSelector(pickingService, (state) => state.matches("endPicking.error"));
  const { shouldShowOrderCompletionWarning, setPickingUIState } = usePickingUIStore((state) => ({
    shouldShowOrderCompletionWarning: state.shouldShowOrderCompletionWarning,
    setPickingUIState: state.setPickingUIState,
  }));

  const handleValueChange = (value: string) => {
    const isValid = isValidShelfId(value);
    setIsAddButtonVisible(value !== "");
    setErrorTextId(
      isValid || !value
        ? undefined
        : ("pages.picking.picking.assign-shelves.input-error-invalid-format" as IntlMessageId),
    );
  };

  const scanShelf = (newShelfId: string) => {
    if (!isValidShelfId(newShelfId)) {
      setErrorTextId(
        "pages.picking.picking.assign-shelves.input-error-invalid-format" as IntlMessageId,
      );
      return;
    }

    if (newShelfId === "") {
      return;
    }

    if (shelvesIds.find((id) => id === newShelfId)) {
      setErrorTextId("pages.picking.assign-shelves.input-error-already-scanned");
      return;
    }

    send({ type: "ADD_SHELF", shelfId: newShelfId });
    setIsAddButtonVisible(false);
    refInput?.current?.resetValue();
  };

  const onFormSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (errorTextId) {
      return;
    }
    scanShelf(typedValue ?? "");
    setTypedValue(undefined);
    send({ type: endPickingError ? "RETRY_END_PICKING" : "END_PICKING" });
  };

  const onComfirmOrderCompletionWarning = () => {
    send({ type: "CONFIRM_ORDER_COMPLETION" });
  };

  const {
    isOpen: isShelfAssignmentSkipConfirmationModalOpen,
    onOpen: openShelfAssignmentSkipConfirmationModal,
    onClose: closeShelfAssignmentSkipConfirmationModal,
  } = useDisclosure();

  const onClickSkipButton = () => {
    openShelfAssignmentSkipConfirmationModal();
  };

  const onConfirmSkip = () => {
    send({ type: "RESET_SHELVES" });
    send({ type: endPickingError ? "RETRY_END_PICKING" : "END_PICKING" });
    closeShelfAssignmentSkipConfirmationModal();
  };

  useEffect(() => {
    setPickingUIState({ shouldShowOrderCompletionWarning: false });
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page isCentered scrollBehavior="auto">
      <HeaderS textAlign="center">
        <FormattedMessage id="pages.picking.assign-shelves.title" />
      </HeaderS>
      <chakra.form onSubmit={onFormSubmit} alignItems="center" w="100%">
        {shelvesIds.map((shelfId) => (
          <RemovableListItem
            key={shelfId}
            title={shelfId}
            onClickRemove={() => {
              setTypedValue(undefined);
              send({ type: "REMOVE_SHELF", shelfId });
            }}
          />
        ))}
        <ScannableInput
          ref={refInput}
          inputName="container-id"
          inputMode="numeric"
          error={errorTextId ? intl.formatMessage({ id: errorTextId }) : undefined}
          placeholder={intl.formatMessage({
            id: "pages.picking.assign-shelves.input-placeholder",
          })}
          onKeyboardChange={(value) => {
            send({ type: "SET_INPUT_METHOD", inputMethod: "MANUAL" });
            setTypedValue(value);
          }}
          onScan={(scannedValue) => {
            send({ type: "SET_INPUT_METHOD", inputMethod: "SCAN" });
            setTypedValue(undefined);
            scanShelf(scannedValue);
          }}
          isAutoFocus
          showKeyBoard
          onValueChange={handleValueChange}
        />
        {isAddButtonVisible && (
          <Flex pt="s100" justify="flex-end">
            <Button
              bg="pinkFlink.200"
              aria-label="Add shelf"
              alignSelf="flex-end"
              data-testid="add-shelf-button"
              onClick={() => {
                if (refInput.current?.value) {
                  scanShelf(refInput.current.value);
                }
              }}
            >
              <AddIcon mr="s100" color="pinkFlink.500" />
              <BodyM color="pinkFlink.500">
                {intl.formatMessage({
                  id: "pages.picking.assign-shelves.add-shelf-button",
                })}
              </BodyM>
            </Button>
          </Flex>
        )}
        <EndPickingButton
          beforeSubmit={() => {
            if (typedValue && !shelvesIds.find((s) => s === typedValue)) {
              send({ type: "ADD_SHELF", shelfId: typedValue });
              setTypedValue(undefined);
              refInput?.current?.resetValue();
            }
          }}
          isButtonDisabled={(!shelvesIds.length && !typedValue) || !!errorTextId}
          mt="s300"
        />
      </chakra.form>
      <SkipScanningButton onClickSkipButton={onClickSkipButton} isDisabled={endPickingLoading} />
      {shouldShowOrderCompletionWarning && (
        <OrderCompletionWarningModal onClick={onComfirmOrderCompletionWarning} />
      )}
      {isShelfAssignmentSkipConfirmationModalOpen && (
        <ShelfAssignmentSkipConfirmationModal
          onClickYes={onConfirmSkip}
          onClickNo={closeShelfAssignmentSkipConfirmationModal}
        />
      )}
    </Page>
  );
}
